<template>
    <div class="template-full">
        <div class="close-btn" v-on:click.prevent="$parent.showTemplateInfo = false"></div>
        <div class="template-full__left">
            <div class="template-full__preview" v-bind:style="wrapperStyle">
                <SlidePreviewContent
                    v-if="preview"
                    :vjson="preview.slides[currentSlide]"
                    :size="preview.meta.size"
                    :meta="preview.meta"
                />
            </div>
            <div class="template-full__preview-controls" v-if="preview">
                <div class="template-full__preview-control template-full__preview-control_prev" @click="prevSlide"></div>
                <div class="template-full__preview-counter">{{ currentSlide + 1 }} / {{ preview.slides.length }}</div>
                <div class="template-full__preview-control template-full__preview-control_next" @click="nextSlide"></div>
            </div>
        </div>
        <div class="template-full__right">
            <div class="template-full__title">{{ name }}</div>
            <div class="template-full__description">{{ description }}</div>
            <div class="template-full__tags">
                <div class="template-full__tag" v-for="t in tags">{{ t }}</div>
            </div>
            <div class="template-full__price">{{ displayPrice }}</div>
            <div class="btn" v-if="!opts.is_slideTemplate" @click="$parent.chooseTemplate(id, favorite)">
                Choose and apply
            </div>
            <div class="template-full__edit" v-if="editor && currentPage.user.role === 1">
                <div class="edit-btn" @click.prevent="showTemplateEditDialog = true; fillTemplateEditParams()">
                    <svg class="media-browser__item-pen" width="14" height="14" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.775 1.952c-.007-.01-.01-.021-.017-.03a1.464 1.464 0 0 0-.185-.228L12.306.428c-.549-.55-1.51-.552-2.062 0L1.458 9.213l3.33 3.329 8.785-8.786c.07-.07.132-.146.185-.227.007-.01.01-.02.017-.03a1.446 1.446 0 0 0 0-1.547zm-1.61.296l-8.167 8.167a.293.293 0 0 1-.413 0 .292.292 0 0 1 0-.413l8.167-8.166a.292.292 0 0 1 .413.412zM1.13 9.71L.011 13.628a.292.292 0 0 0 .36.36l3.919-1.12L1.13 9.71z"
                            fill="#82568E"
                        />
                    </svg>
                    Info
                </div>
                <div class="edit-slides-btn" @click.prevent="editTemplate">
                    <svg width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <g mask="url(#mask-1)" fill="#82568E" transform="scale(.9)">
                            <path
                                d="M17.93999995 0H2.05999986c-1.13771 5e-8-2.06.922294-2.06 2.06V17.94000031c1.7e-7 1.13771.922294 2.06 2.06 2.06h15.88-9e-8c1.13771 5e-8 2.06-.922293 2.06-2.06v-15.88c0-1.13771-.922293-2.06-2.06-2.06zm-15.88 1.74h15.88-1e-8c.176731 0 .32.143269.32.32V17l-2.52-3.15h-1e-8c-.240645-.343902-.714514-.427608-1.05842-.186963a.7600194.7600194 0 0 0-.131584.116963l-2.95 3.3-4.25-6.36c-.152114-.191122-.385889-.298733-.63-.29l4e-8-1e-8c-.242868.0108455-.465892.137225-.6.34l-4.38 6.16v-14.87 5e-8c-3e-8-.176731.143269-.32.32-.32z"
                            />
                            <path
                                d="M15.1896 4.81039c1.18719 1.18719 1.18719 3.11202 0 4.29921-1.18719 1.18719-3.11202 1.18719-4.29921 0-1.18719-1.18719-1.18719-3.11202 0-4.29921 1.18719-1.18719 3.11202-1.18719 4.29921 0"
                            />
                        </g>
                        <defs>
                            <mask id="mask-1">
                                <path
                                    fill="#FFF"
                                    d="M1.3 20C.582036 20 0 19.417964 0 18.7V1.3C0 .582036.582036 0 1.3 0h17.4c.717964 0 1.3.582036 1.3 1.3v17.4c0 .717964-.582036 1.3-1.3 1.3z"
                                />
                            </mask>
                        </defs>
                    </svg>
                    Slides
                </div>
                <div class="delete-btn" v-if="!opts.is_slideTemplate" @click.prevent="showDeleteDialog = true">
                    <svg width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none">
                            <path d="M0 0h24v24H0V0z"/>
                            <path
                                fill="#82568E"
                                d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"
                            />
                            <path d="M0 0h24v24H0z"/>
                        </g>
                    </svg>
                    Delete
                </div>
            </div>
        </div>
        <div
            class="dialog template-full__delete-dialog"
            v-if="showDeleteDialog"
            @click.prevent="showDeleteDialog = false"
        >
            <div class="dialog__window" @click.stop="">
                <div class="dialog__header">Delete template permanently?</div>
                <div class="dialog__content">
                    <div class="flex-row dialog__actions">
                        <div class="btn btn__no-bg" @click.prevent="showDeleteDialog = false">Cancel</div>
                        <div class="btn" @click.prevent="deleteTemplate">Delete</div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="dialog template-full__edit-dialog"
            v-if="showTemplateEditDialog"
            @click.prevent="showTemplateEditDialog = false"
        >
            <div class="dialog__window" @click.stop="">
                <div class="dialog__header">Edit template info</div>
                <div class="dialog__content">
                    <form class="template-full__edit-form" @submit.prevent="">
                        <div class="flex-row">
                            <div class="flex-col">
                                <div class="template-full__edit-form-item">
                                    <label>Title</label>
                                    <input type="text" v-model="editName"/>
                                </div>
                                <div class="template-full__edit-form-item">
                                    <label>Tags</label><input type="text"
                                                                                                     v-model="tplTagInput"
                                                                                                     @keydown="tagsKeyDown"/>
                                    <div class="tags">
                                        <div class="tag" v-for="t in tplTags">{{ t }}
                                            <div class="tag__delete" @click="deleteTag(t)"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="template-full__edit-form-item" v-if="!opts.is_slideTemplate">
                                    <label>Price</label><input type="text" v-model="editPrice"/></div>
                            </div>
                            <div class="flex-col">
                                <div class="template-full__edit-form-item"><label>Description</label><textarea
                                    v-model="editDescription"></textarea><br/><label><input type="checkbox"
                                                                                            v-model="isPublic"/>Published</label>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="flex-row dialog__actions">
                        <div class="btn btn__no-bg" @click.prevent="showTemplateEditDialog = false">Cancel</div>
                        <div class="btn" @click.prevent="updateTemplateInfo">Update</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="spinner spinner_bg_white" :class="{'spinner_active': busy}">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke="#aaaaaa"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-loader"
            >
                <path
                    d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>
            </svg>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import JSZip from 'jszip';
import Ajax from '@/mixins/Ajax';
import SlidePreviewContent from '@/components/SlidePreviewContent';
import { apiDelete, apiPut } from '@/models/Api';

export default {
    name: 'TemplateInfo',
    inject: ['currentPage'],
    props: ['opts'],
    mixins: [Ajax],

    components: {
        SlidePreviewContent
    },

    data() {
        return {
            name: '',
            preview: null,
            currentSlide: 0,
            price: 0,
            description: '',
            tags: [],
            editName: '',
            editDescription: '',
            editPrice: '',
            isPublic: false,
            tplTagInput: '',
            tplTags: '',
            scale: 1,
            thumbScale: 1,
            previewWidth: 0,
            favorite: false,
            editor: true, // отображать кнопки редактирования шаблона
            showDeleteDialog: false,
            showTemplateEditDialog: false,
            busy: false
        };
    },

    computed: {
        displayPrice() {
            if(this.price > 0) {
                return '$' + this.price;
            } else {
                return 'Free';
            }
        },
        slideStyle() {
            // console.log @preview.slides
            return {
                height: this.preview.presentationMeta.height + 'px',
                width: this.preview.presentationMeta.width + 'px',
                transform: 'translate3d(-' + (50 * this.scale) + '%, -' + (50 * this.scale) + '%, 0px) scale(' + this.scale + ')'
            };
        },
        wrapperStyle() {
            let pt;
            if(!this.preview) {
                pt = 0;
            } else {
                pt = (this.preview.meta.size.h / this.preview.meta.size.w) * this.previewWidth;
            }
            pt = Math.min(430, pt);
            return {
                // 'padding-top':'300px'
                'padding-top': pt + 'px'
            };
        }
    },

    methods: {
        fillTemplateEditParams() {
            // console.log @opts
            this.editName = this.opts.name;
            this.editPrice = this.opts.price;
            this.tplTags = _.clone(this.opts.tags);
            this.editDescription = this.description;
            return this.isPublic = this.public;
        },

        tagsKeyDown(e) {
            if((e.keyCode === 13) && (e.target.value !== '') && (_.indexOf(this.tplTags, e.target.value) === -1)) {
                this.tplTags.push(e.target.value);
                this.tplTagInput = '';
            }
        },

        deleteTag(tag) {
            const idx = _.indexOf(this.tplTags, tag);
            if(idx !== -1) {
                this.tplTags.splice(idx, 1);
            }
        },

        async deleteTemplate() {
            this.showDeleteDialog = false;

            this.busy = true;
            const res = await apiDelete(`/api/v1template/${this.opts.id}`);
            this.busy = false;

            if(res) {
                this.$parent.templateDeleted(this.opts.id);
            }
        },

        editTemplate() {
            // console.log 'editTemplate ' + @opts.id
            if(!this.opts.is_slideTemplate) {
                localStorage.setItem('isTemplate', true);
            }
            localStorage.setItem('presentationId', this.opts.id);
            let url = '/editor';
            if(window.location.href.indexOf('localhost') !== -1) {
                url += '.html';
            }
            window.location = url;
        },

        async updateTemplateInfo() {
            if(this.opts.is_slideTemplate) {
                this.updateSlideTemplateInfo();
                return;
            }

            this.busy = true;
            this.showTemplateEditDialog = false;
            const data = {
                name: this.editName,
                description: this.editDescription,
                price: this.editPrice,
                tags: JSON.stringify(this.tplTags),
                public: this.isPublic
            };

            const res = await apiPut(`/api/v1/template/${this.opts.id}`, data);
            this.busy = false;

            if(res) {
                this.opts.name = this.editName;
                this.description = this.editDescription;
                this.price = this.editPrice;
                this.tags = this.tplTags;
            }
        },

        async updateSlideTemplateInfo() {
            this.busy = true;
            this.showTemplateEditDialog = false;
            const data = {
                name: this.editName,
                public: this.isPublic
            };

            const res = await apiPut(`/api/v1/slide_template/${this.opts.id}`, data);
            this.busy = false;

            if(res) {
                this.name = this.editName;
                this.opts.name = this.editName;
                this.public = this.isPublic;
                this.opts.public = this.isPublic;
            }
        },

        calcScale() {
            let thumbScale;
            let scale = 1;
            const ph = this.preview.meta.size.h;
            const pw = this.preview.meta.size.w;
            // vh = $(@$el).find('.presentation-preview__body').height()
            const vw = $(this.$el).find('.template-full__preview').width();
            const tw = $(this.$el).find('.template-full__thumbs-item').width();
            const vh = Math.round((ph / pw) * vw);
            const th = Math.round((ph / pw) * tw);
            // console.log(pw,ph,vw,vh)
            if((pw / ph) > (vw / vh)) {
                scale = vw / pw;
            } else {
                scale = vh / ph;
            }

            if((pw / ph) > (tw / th)) {
                thumbScale = tw / pw;
            } else {
                thumbScale = th / ph;
            }

            this.scale = scale;
            this.thumbScale = thumbScale;
            this.previewWidth = vw;
            // console.log 'previewWidth'+@previewWidth
            // console.log 'scale '+scale
            // console.log 'thumbscale '+thumbScale
        },

        loadPreview() {
            const that = this;
            if(this.previewURL) {
                $.ajax({
                    url: this.previewURL,
                    success(dat) {
                        JSZip.loadAsync(
                            dat,
                            { base64: true }
                        ).then(zip => {
                            return zip.file(Object.keys(zip.files)[0]).async('string').then(str => {
                                const json = JSON.parse(str);
                                that.preview = json;
                                console.log(json);
                                that.calcScale();
                            });
                        });
                    }
                })
                    .fail(dat => console.log(dat));
            }
        },

        prevSlide() {
            if(this.currentSlide === 0) {
                return;
            }
            this.currentSlide--;
        },

        nextSlide() {
            if(this.currentSlide === (this.preview.slides.length - 1)) {
                return;
            }
            this.currentSlide++;
        }
    },


    created() {
        const that = this;
        _.each(this.opts, function (v, k) {
            that[k] = v;
        });
    },
    mounted() {
        this.loadPreview();
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/common.scss';

.template-full {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background: #fff;
    border-radius: 3px;
    padding: 60px 0;

    &__delete-dialog, &__edit-dialog {
        position: absolute;
        z-index: 1;
    }

    &__edit-form {
        position: relative;
        display: block;
        margin-bottom: 20px;
        text-align: left;
        width: 600px;

        .flex-col {
            width: 100%;
            padding: 0 5px;
        }

        &-item {
            margin-bottom: 20px;
            padding: 0 5px;
        }

        textarea {
            margin-top: 28px;
        }
    }

    &__edit {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 20px;
    }

    .edit-btn, .edit-slides-btn, .delete-btn {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        z-index: 1;
        background: #eee;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        padding: 5px 10px;
        border-radius: 3px;
        height: 32px;
        box-sizing: border-box;
        // color:$accent_color;

        svg {
            position: relative;
            display: block;
            margin-right: 5px;
        }

        &:hover {
            opacity: .7;
        }
    }

    .delete-btn {
        margin-right: 0;
    }

    &__left {
        position: relative;
        display: flex;
        width: 100%;
        flex-grow: 0;
        flex-direction: column;
        padding: 0 60px;
        box-sizing: border-box;
        border-right: 1px solid #ddd;
        @media #{$mq-1366} {
            padding: 0 40px;
        }
    }

    &__right {
        position: relative;
        flex-shrink: 0;
        padding: 0 60px;
        box-sizing: border-box;
        width: 500px;

        @media #{$mq-1366} {
            width: 350px;
            padding: 0 40px;
        }

        .btn {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            @media #{$mq-1366} {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

    }

    &__preview {
        position: relative;
        display: block;
        width: 100%;
        overflow: hidden;

        .slide {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform-origin: 0 0;
        }

        .slide-bg {
            opacity: 1;
        }
    }

    &__thumbs {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 120px;
        padding: 20px 0;
        // border-right: 1px solid #ddd;

        &-item {
            position: relative;
            display: block;
            width: 100px;
            height: 80px;
            overflow: hidden;

            .slide {
                position: absolute;
                display: block;
                top: 50%;
                left: 50%;
                transform-origin: 0 0;
            }

            .slide-bg {
                opacity: 1;
            }
        }
    }

    &__title {
        position: relative;
        display: block;
        font-size: 32px;
    }

    &__description {
        position: relative;
        display: block;
        margin-top: 20px;
    }

    &__tags {
        position: relative;
        display: block;
        margin-top: 20px;
    }

    &__tag {
        position: relative;
        display: inline-block;
        font-size: 12px;
        padding: 3px 5px;
        border-radius: 3px;
        margin: 0 5px 5px 0;
        background: #eee;
    }

    &__price {
        position: relative;
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &__preview-controls {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
    }

    &__preview-counter {
        position: relative;
        display: block;
        padding: 0 10px;
        font-size: 14px;
        color: #777;
    }

    &__preview-control {
        position: relative;
        display: block;
        height: 24px;
        width: 24px;
        cursor: pointer;
        opacity: .5;

        &:hover {
            opacity: 1;
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            height: 8px;
            width: 8px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &_prev:after {
            border-bottom: 2px solid #aaa;
            border-left: 2px solid #aaa;
        }

        &_next:after {
            border-top: 2px solid #aaa;
            border-right: 2px solid #aaa;
        }
    }

    .spinner {
        z-index: 1;
    }
}
</style>
