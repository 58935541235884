<template>
    <div class="page-mobile-overlay">
        <h3>{{ $t('mobileNotAvailible') }}</h3>

        <router-link class="btn" to="/">
            {{ $t('back') }}
        </router-link>
    </div>
</template>

<script>
export default {
    name: "PageMobileOverlay",
    mounted () {
        const body = document.body;
        body.style.width = '100vw';
        body.style.height = '100vh';
        body.style.overflow = 'hidden';
        body.style.backgroundColor = '#2d2d2d';
    },
    beforeDestroy () {
        const body = document.body;
        body.style.width = 'unset';
        body.style.height = 'unset';
        body.style.overflow = 'unset';
        body.style.backgroundColor = '#fff';
    }
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/rfs.scss';

.page-mobile-overlay {
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    background: #2d2d2d;
    @include padding(20px);
    flex-direction: column;
    justify-content: center;

    h3 {
        display: block;
        color: #F1F1F1;
        user-select: none;
        position: relative;
        text-align: center;
        @include font-size(21px);
        @include line-height(26px);
    }

    a {
        color: #fff;
        font-weight: bold;
        text-decoration: none;
    }
}
</style>

<i18n>
{
    "en": {
        "back": "Back",
        "mobileNotAvailible": "This page is not available on mobile devices yet."
    },
    "ru": {
        "back": "Назад",
        "mobileNotAvailible": "Эта страница пока недоступна для мобильных устройств."
    }
}
</i18n>
