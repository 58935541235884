<template>
    <div
        class="thumbs-bar__item"
        :class="{'thumbs-bar__item__active': active}"
        @click="clicked($event)"
        :style="{height: height, 'min-height': height}"
    >
        <slide_preview_content :vjson="slide" :size="size" :meta="meta"/>
    </div>
</template>

<script>
/* eslint-disable */
import SlidePreviewContent from "@/components/SlidePreviewContent";

export default {
    name: "SlidePreview",
    inject: ['currentPage'],
    props: [
        'index',
        'slide',
        'active',
        'size',
        'meta',
        'autoHeight'
    ],
    components: {
        slide_preview_content: SlidePreviewContent
    },
    methods: {
        clicked(e) {
            this.currentPage.setActiveSlide(this.index);
        }
    },

    computed: {
        height() {
            if (this.autoHeight) {
                const ratio = this.size.w / this.size.h;
                return `${90 / ratio}px`;
            } else {
                return '';
            }
        }
    }
}
</script>

<style>

</style>
