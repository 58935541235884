<template>
    <div class="profile-settings profile-settings_general">
        <div class="user-message" :class="msgClasses" @click="showMsg=false">{{ $t(message) }}</div>
        <div class="flex-row">
            <div class="flex-col">
                <div class="user-profile">
                    <div class="user-profile__picture">
                        <div class="user-profile__letter">{{ firstLetter }}</div>
                    </div>
                    <div class="user-profile__name">{{ user.name }}</div>
                </div>
                <div class="user-stats">
                    <div class="user-stats__row">
                        <div class="user-stats__key">{{ $t('projects') }}</div>
                        <div class="user-stats__value">{{ user.projectsUsed }}</div>
                    </div>
                    <div class="user-stats__row">
                        <div class="user-stats__key">{{ $t('storage_used') }}</div>
                        <div class="user-stats__value">{{ user.storageUsed }} / {{ user.storageQuota }} mb</div>
                    </div>
                </div>
            </div>
            <div class="flex-col">
                <form v-if="!changingPass">
                    <div class="profile-settings__item">
                        <label>{{ $t('name') }}</label>
                        <input type="text" v-model="profile.name"/>
                    </div>
                    <div class="profile-settings__item">
                        <label>{{ $t('email') }}</label>
                        <input type="text" v-model="profile.email" v-on:input="compareEmails"/>
                    </div>
                    <div class="profile-settings__item" v-if="changingEmail">
                        <label>{{ $t('confirm_password') }}</label>
                        <input type="password" v-model="profile.password"/>
                    </div>
                    <div class="profile-settings__item profile-settings__item_flex">
                        <div class="btn" @click="setUserData">{{ $t('save_settings') }}</div>
                        <div class="btn btn__no-bg" v-on:click="changingPass = true">{{ $t('change_password') }}</div>
                    </div>
                </form>
                <form v-if="changingPass">
                    <div class="profile-settings__item">
                        <label>{{ $t('old_password') }}</label>
                        <input type="password" v-model="profile.password"/>
                    </div>
                    <div class="profile-settings__item">
                        <label>{{ $t('new_password') }}</label>
                        <input type="password" v-model="profile.password_new"/>
                    </div>
                    <div class="profile-settings__item">
                        <label>{{ $t('confirm_password') }}</label>
                        <input type="password" v-model="profile.password_new_confirmation"/>
                    </div>
                    <div class="profile-settings__item profile-settings__item_flex">
                        <div class="btn" v-on:click="updatePass">{{ $t('update_password') }}</div>
                        <div class="btn btn__no-bg" v-on:click="changingPass = false; showMsg=false">{{
                                $t('cancel')
                            }}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Ajax from '@/mixins/Ajax';
import Toast from '@/mixins/Toast';
import { apiPut } from '@/models/Api';

export default {
    name: 'Profile',
    inject: ['currentPage'],
    mixins: [Ajax, Toast],
    props: ['user'],
    data() {
        return {
            profile: { // данные пользователя для редактирования
                name: 'Username',
                email: 'email@domain.com',
                emailOld: '',
                password: '',
                password_confirmation: '', // сане нужно именно такое название
                password_new: '',
                password_new_confirmation: '',
                plan: 'Free'
            },
            changingEmail: false,
            changingPass: false,
            msgType: 'error', // error / warning / success
            message: 'Password is incorrect',
            showMsg: false
        };
    },
    computed: {
        firstLetter: function () {
            return this.user.name.substr(0, 1).toUpperCase();
        },
        msgClasses: function () {
            const c = ['user-message_' + this.msgType];
            if(this.showMsg) {
                c.push('user-message_active');
            }
            return c;
        }
    },
    watch: {
        'user.name': function () {
            this.profile.name = this.user.name;
        },
        'user.email': function () {
            this.profile.email = this.user.email;
        },
        changingPass: function () {
            if(this.changingPass) {
                this.password = '';
                this.password_new = '';
                this.password_new_confirmation = '';
            }
        }
    },
    methods: {
        compareEmails() {
            this.changingEmail = this.user.email !== this.profile.email;
        },
        showMessage(opts) {
            var defOpts, i, k, len, that, v;
            defOpts = {
                t: 3000,
                autoHide: true
            };
            for (v = i = 0, len = opts.length; i < len; v = ++i) {
                k = opts[v];
                // console.log k, v
                defOpts.k = v;
            }
            this.showMsg = true;
            that = this;
            if(defOpts.autoHide) {
                setTimeout(function () {
                    that.showMsg = false;
                }, defOpts.t);
            }
        },
        async setUserData() {
            const data = {};

            if(this.user.name !== this.profile.name) {
                data.name = this.profile.name;
            }
            if(this.changingEmail) {
                data.email = this.profile.email;
                data.password = this.profile.password;
            }
            if(Object.keys(data).length === 0) {
                return;
            }

            const res = await apiPut(`/api/v1/user/${this.$store.state.user.id}`, data);

            if(res) {
                if(this.changingEmail) {
                    this.changingEmail = false;
                    await this.$store.commit('setUserParam', { key: 'emailVerified', value: 0 });
                    await this.$store.commit('setUserParam', { key: 'email', value: this.profile.email });
                    this.showSuccessToast('VerifyEmailSent', 'top-center');
                }

                if(this.user.name !== this.profile.name) {
                    await this.$store.commit('setUserParam', { key: 'name', value: this.profile.name });
                }
            }
        },
        async updatePass() {
            const data = {
                password: this.profile.password,
                password_new: this.profile.password_new,
                password_new_confirmation: this.profile.password_new_confirmation
            };

            const res = await apiPut(
                `/api/v1/user/${this.$store.state.user.id}/password/update`,
                data,
                false
            );

            if(res) {
                this.changingPass = false;
                this.msgType = 'success';
                this.message = 'Password successfully changed';
                this.showMessage({ t: 3500, autoHide: true });

                this.profile.password = '';
                this.profile.password_new = '';
                this.profile.password_new_confirmation = '';
            } else {
                this.message = res?.error || '';
                this.msgType = 'error';
                this.showMessage({ t: 5000, autoHide: true });
            }
        }
    },
    created() {
        this.profile.name = this.user.name;
        this.profile.email = this.user.email;
    }
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/vars.scss';

.profile-settings {
    position: relative;
    display: block;
    margin: 78px auto 0;
    padding: 84px 0;
    background: #fff;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 1200px;
    box-shadow: 0 0 15px rgba(41, 0, 41, .07);
    overflow: hidden;

    @media #{$mq-1366} {
        margin: 0;
        padding: 96px 0 0;
        height: calc(100vh - 96px);
        max-height: none;
        width: auto;
        max-width: none;
    }

    @media (max-height: 767px) {
        margin: 0 auto;
        padding: 96px 0 0;
        height: calc(100vh - 96px);
        max-height: none;
        width: auto;
        max-width: none;
    }


    &_general {
        overflow: auto;

        @media (max-width: 1279px) {
            flex-direction: row;
            flex-wrap: wrap;

            .flex-row {
                flex-direction: column;
                max-width: 600px;
            }

            .profile-settings__item {
                padding: 10px 0;
                width: 50%;
                box-sizing: border-box;

                @media #{$mq-600} {
                    width: 100%;
                }

                &:last-child {
                    width: 100%;
                }
            }
        }
    }

    &__title {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .flex-row {
        position: relative;
        // margin: 0;
        margin: 0 auto;
        width: 100%;
        align-self: stretch;
    }

    .flex-col {
        padding: 0 80px;
        width: 100%;
        box-sizing: border-box;

        &:first-child {
            border-right: 1px solid #eee;
            @media (max-width: 1279px) {
                border: none;
            }
        }

        @media #{$mq-1440} {
            padding: 0 70px;
        }
        @media (max-width: 1365px) {
            padding: 0 40px;
        }
    }

    form {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    &__item {
        position: relative;
        padding: 10px 0;

        &_underline {
            border-bottom: 1px solid #ddd;
            // margin-bottom: 12px;
        }

        &_flex {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .btn {
                position: relative;
                display: block;
                margin: 0 24px 0 0;
                height: auto;

                &:last-child {
                    margin: 0;
                }

                &_to-right {
                    margin-left: auto;
                }
            }
        }
    }

    .user-profile {
        padding: 6px 0 0;
        margin-bottom: 48px;

        @media #{$mq-1200} {
            margin-bottom: 24px;
        }

        &__name {
            font-size: 32px;
            margin-top: -2px;
            font-weight: 500;
            color: $space_gray;
            text-transform: capitalize;
        }

        &__picture {
            border-color: rgba(0, 0, 0, .1);
        }

        &__letter {
            color: $space_gray
        }

    }

    .user-stats {
        position: relative;
        display: block;
        font-size: 16px;

        @media #{$mq-1366} {
            font-size: 14px;
        }

        @media (max-width: 1279px) {
            border-bottom: 1px solid #ddd;
            padding-bottom: 24px;
            margin-bottom: 18px;
        }

        &__row {
            position: relative;
            display: flex;
            padding: 10px 0;
            justify-content: space-between;
        }

        &__key {
            position: relative;
            display: block;
            opacity: .5;
            padding-right: 20px;
            @media (max-width: 1279px) {
                width: 50%;
            }
            @media #{$mq-640} {
                width: auto;
            }
        }

        &__value {
            position: relative;
            display: block;
            @media (max-width: 1279px) {
                width: 50%;
            }
            @media #{$mq-640} {
                width: auto;
            }
        }
    }

    input[type = "text"], select {
        padding: 7px 0;
        height: 39px;
        box-sizing: border-box;
    }

    .profile-settings__item { //, .create-form__item{
        width: 100%;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "projects": "Projects",
        "storage_used": "Storage used",
        "name": "Name",
        "email": "Email",
        "confirm_password": "Confirm password",
        "old_password": "Old password",
        "new_password": "New password",
        "update_password": "Update password",
        "save_settings": "Save settings",
        "change_password": "Change password",
        "cancel": "Cancel"
    },
    "ru": {
        "projects": "Презентации",
        "storage_used": "Хранилища израсходовано",
        "name": "Имя",
        "confirm_password": "Подтвердить пароль",
        "old_password": "Старый пароль",
        "new_password": "Новый пароль",
        "update_password": "Изменить пароль",
        "email": "Email",
        "save_settings": "Сохранить настройки",
        "change_password": "Поменять пароль",
        "cancel": "Отмена"
    }
}
</i18n>
