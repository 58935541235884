<template>
    <div>
        <v-client-table
            ref="table"
            :data="data"
            :columns="columns"
            :options="options"
            @filter="filterData"
        >
            <span slot="activities" slot-scope="props">
                <svg
                    class="icon"
                    viewBox="0 0 512 512"
                    @click="() => login(props.row.id)"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                </svg>
            </span>
        </v-client-table>
        <Pagination
            v-model="page"
            :records="count"
            :per-page="perPage"
            :options="paginationOptions"
            @paginate="page => getData(page)"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import $ from 'jquery';
import { apiGet } from '@/models/Api';
import { ClientTable } from 'vue-tables-2';
import Pagination from 'vue-pagination-2';
import { UserSingleton } from '@/models/UserSingleton';

window.$ = $;
Vue.use(ClientTable, {}, false, 'bootstrap3', {});

export default {
    name: 'UserTable',
    components: {
        Pagination
    },
    data: () => ({
        page: 1,
        count: 0,
        perPage: 20,
        filters: {},
        data: [],
        columns: ['id', 'email', 'name', 'created_at', 'activities'],
        options: {
            filterable: ['id', 'email', 'name', 'created_at'],
            filterByColumn: true,
            perPage: 20,
            sortable: [],
            perPageValues: [],
            pagination: false,
            headings: {
                'id': 'ID',
                'name': 'Имя',
                'email': 'Email',
                'created_at': 'Дата регистрации',
                'activities': ''
            },
            columnsClasses: {
                id: 'id_col',
                name: 'name_col',
                email: 'email_col',
                'created_at': 'created_at_col',
                'activities': 'activities_col'
            },
            filterAlgorithm: {
                id: () => true,
                email: () => true,
                name: () => true,
                'created_at': () => true
            },
            texts: {
                count: 'Показано с {from} по {to} из {count} записей|Записей: {count}|Одна запись',
                first: 'Первая',
                last: 'Последняя',
                filter: 'Отфильтровать:',
                filterPlaceholder: 'Поиск:',
                limit: 'Записей:',
                page: 'Страница:',
                noResults: 'Записей не найдено',
                filterBy: '{column}',
                loading: 'Загрузка...',
                defaultOption: 'Выбрать {column}',
                columns: 'Колонки'

            }
        },
        paginationOptions: {
            texts: {
                count: 'Показано с {from} по {to} из {count} записей|Записей: {count}|Одна запись',
                limit: 'Записей:',
                page: 'Страница:',
                noResults: 'Записей не найдено'
            }
        }
    }),
    methods: {
        async login (id) {
            const res = await apiGet('/api/v1/manager/login', { id });

            if (res) {
                UserSingleton.setToken(res);
                UserSingleton.saveToken(res);
                await UserSingleton.setInstance(null);
                const user = await UserSingleton.getInstance();

                console.log(user);
                if (user) {
                    await this.$store.dispatch('autoUserUpdate');
                    await this.$router.push('/account');
                }
            }
        },
        filterData (payload) {
            if (payload.value) {
                this.filters[payload.name] = payload.value;
            } else {
                delete this.filters[payload.name];
            }

            this.getData();
        },
        async getData (page = 1) {
            this.$refs?.table?.setLoadingState(true);

            const res = await apiGet(
                '/api/v1/manager/users',
                {
                    ...this.filters,
                    take: this.perPage,
                    skip: this.perPage * (this.page - 1)
                });
            this.$refs?.table?.setLoadingState(false);

            if (res) {
                this.data = res.data;
                this.page = page;
                this.count = res.count;
            }
        },
        refresh () {
            this.filters = {};
            this.$refs.table.resetQuery();
            this.getData(1);
        }
    },
    created () {
        this.getData();
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/table.scss";

.name_col, .email_col, .created_at_col {
    width: 30%;
    max-width: 30%;
}

.name_col, .email_col, .created_at_col {
    user-select: text;
}

.activities_col {
    width: 50px;
    max-width: 50px;
    text-align: center;

    .icon {
        width: 20px;
        fill: #82568E;
        cursor: pointer;
    }
}
</style>
