<template>
    <div
        class="presentation-preview"
        @click="onClick"
        onclick="App.reachGoal('clickProject'); return true;"
        :class="{'presentation-preview_selected': isSelected}"
    >
        <div class="presentation-preview__body">
            <div class="presentation-preview__loading" v-if="preview && preview.loading"></div>
            <div class="presentation-preview__error" v-if="!preview">Error occured</div>
                <SlidePreview
                    v-if="preview && !preview.loading"
                    :index="0"
                    :slide="preview.slides[0]"
                    :active="false"
                    :size="preview.meta.size"
                    :meta="preview.meta"
                />
        </div>

        <div class="presentation-preview__info">
            <div class="presentation-preview__info-left">
                <div class="presentation-preview__title" :title="opts.name">{{ opts.name }}</div>
                <div class="presentation-preview__edited" v-if="!opts.is_template && !opts.is_slideTemplate">
                    {{ $t('updated') }}: {{ updatedAt }}
                </div>
            </div>
            <div class="presentation-preview__info-right">
                <div class="presentation-preview__price" v-if="opts.is_template && !isMy">{{ displayPrice }}</div>
                <div class="presentation-preview__nav-btn" v-if="!opts.is_template || isMy" @click.stop="showNav=true">
                    <svg width="7" height="18" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.9 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM1.567 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm10.666 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                            transform="rotate(90 1.5 3.5)" fill="#181725" fill-rule="nonzero">
                        </path>
                    </svg>
                    <div
                        class="context-menu presentation-preview__nav"
                        :class="{'context-menu_active':showNav}"
                        @click.stop="showNav=false"
                        @mousedown.stop=""
                    >
                        <template v-if="!opts.is_template">
                            <div class="context-menu__item" v-if="currentPage.validBrowser" @click="view()">
                                {{ $t('view') }}
                            </div>
                            <div class="context-menu__item" v-if="opts.archived === 0" @click.stop="renameItem()">
                                {{ $t('rename') }}
                            </div>
                            <div class="context-menu__item" v-if="opts.archived === 0" @click.stop="cloneItem()">
                                {{ $t('clone') }}
                            </div>
                            <div class="context-menu__item" v-if="opts.archived === 0" @click.stop="archiveItem()">
                                {{ $t('archive') }}
                            </div>
                            <div class="context-menu__item" v-if="opts.archived === 1" @click.stop="unArchiveItem()">
                                {{ $t('unarchive') }}
                            </div>
                            <div class="context-menu__item" @click.stop="deleteItem()">
                                {{ $t('delete') }}
                            </div>
                        </template>
                        <template v-if="opts.is_template && isMy">
                            <div class="context-menu__item" @click.stop="removeFromFav()">Delete</div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="presentation-preview__draft" v-if="(opts.is_template || opts.is_slideTemplate) && !opts.public">
            Draft
        </div>
    </div>
</template>

<script>
import JSZip from 'jszip';
import Ajax from '@/mixins/Ajax';

import SlidePreview from '@/components/viewer/SlidePreview';

import { apiGet, customApiRequest } from '@/models/Api';

export default {
    name: 'Presentation',
    mixins: [Ajax],
    inject: ['currentPage'],
    props: ['opts', 'selectedTemplate', 'isMy'],
    components: {
        SlidePreview
    },
    data () {
        return {
            center: {
                x: 0,
                y: 0
            },
            mount: false,
            allSlidesURL: '',
            preview: {
                loading: true
            },
            showNav: false
        };
    },
    computed: {
        updatedAt () {
            const { updated } = this.opts;
            const d = new Date(updated * 1000);
            const dStrings = {
                year: d.getFullYear(),
                month: '' + (d.getMonth() + 1),
                day: '' + d.getDate(),
                hours: '' + d.getHours(),
                minutes: '' + d.getMinutes()
            };

            const testVars = ['month', 'day', 'hours', 'minutes'];

            testVars.forEach(v => {
                if (dStrings[v].length < 2) {
                    dStrings[v] = '0' + dStrings[v];
                }
            });

            const date = [
                dStrings.year,
                dStrings.month,
                dStrings.day
            ].join('-');

            const time = [
                dStrings.hours,
                dStrings.minutes
            ].join(':');
            // console.log upd
            return date + '\u00A0\u00A0' + time;
        },
        isSelected () {
            return this.selectedTemplate === this.opts.id;
        },
        displayPrice () {
            if (this.opts.price > 0) {
                return '$' + this.opts.price;
            } else {
                return 'Free';
            }
        }
    },
    watch: {
        'currentPage.mousePressed.left' () {
            if (this.currentPage.mousePressed.left) {
                setTimeout(() => {
                    this.showNav = false;
                }, 16);
            }
        }
    },
    methods: {
        onClick () {
            if (this.isMy) {
                this.$parent.selectedTemplate = this.opts.id;
                return;
            }
            if (this.showNav) {
                this.showNav = false;
                return;
            }

            if (this.opts.is_template === 1) {
                this.$parent.getTemplateInfo(this.opts.id, this.allSlidesURL);
                return;
            }

            if (this.opts.is_slideTemplate) {
                this.$parent.getTemplateInfo(this.opts.id, this.allSlidesURL);
                return;
            }

            this.editOrView();
        },
        async loadPreview () {
            let url = `/api/v1/presentation/${this.opts.id}/preview`;

            if (this.opts.is_template === 1) {
                url = `/api/v1/template/${this.opts.id}/preview`;
            }

            const res = await apiGet(url);

            if (res) {
                if (res.all != null) {
                    this.allSlidesURL = `${this.$root.storageUrl}/${res.all}`;
                }

                if (res.first != null) {
                    const preview = await customApiRequest('GET', `${this.$root.storageUrl}/${res.first}`);

                    if (preview) {
                        try {
                            const zip = await JSZip.loadAsync(preview, { base64: true });
                            const json = await zip.file(Object.keys(zip.files)[0]).async('string');
                            this.preview = JSON.parse(json);
                        } catch (err) {
                            console.log('Error', err);
                        }
                    }
                }
            }
        },
        editOrView () {
            localStorage.setItem('isTemplate', false);
            localStorage.setItem('presentationId', this.opts.id);

            if (this.currentPage.validBrowser) {
                this.currentPage.gotoUrl('editor');
            } else {
                this.view();
            }
        },
        view () {
            this.currentPage.gotoUrl('viewer', '?id=' + this.opts.id, true);
        },
        renameItem () {
            this.$parent.renameItem(this.opts.id, this.opts.name);
            this.showNav = false;
        },
        cloneItem () {
            this.$parent.cloneItem(this.opts.id);
            this.showNav = false;
        },
        archiveItem () {
            this.$parent.archiveItem(this.opts.id);
            this.showNav = false;
        },
        unArchiveItem () {
            this.$parent.unarchiveItem(this.opts.id);
            this.showNav = false;
        },
        deleteItem () {
            this.$parent.deleteItem(this.opts.id);
            this.showNav = false;
        },
        removeFromFav () {
            this.$parent.removeFromFav(this.opts.id);
            this.showNav = false;
        }
    },
    mounted () {
        this.loadPreview();
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/common.scss';

.presentation-preview {
    position: relative;
    display: flex;
    width: 280px;
    margin: 0 20px 20px 0;
    cursor: pointer;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 0 14px 0 rgba(153, 154, 161, .25);
    flex-shrink: 0;

    &_selected:before {
        content: '';
        position: absolute;
        display: block;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        border-radius: 4px;
        border: 3px solid $accent_color1;
    }

    &:hover {
        box-shadow: 0 0 14px 0 rgba(153, 154, 161, .5);
    }

    &__draft {
        position: absolute;
        display: block;
        right: 0;
        top: 10px;
        padding: 5px 12px;
        color: #fff;
        background: $accent_color1;
        // z-index: 1;
    }

    &__error {
        color: #f009;
    }

    @keyframes pulsate {
        0% {
            transform: translate3D(-50%, -50%, 0) scale(.5);
            opacity: 1;
        }
        100% {
            transform: translate3D(-50%, -50%, 0) scale(3);
            opacity: 0;
        }
    }

    &__loading {
        top: 60%;
        left: 50%;
        display: block;
        position: absolute;
        transition: opacity .5s ease;
        transform: translate(-50%, -50%);

        &:before {
            content: '';
            animation: pulsate 1.5s ease-out infinite;
            position: absolute;
            display: block;
            height: 20px;
            width: 20px;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            // background: $accent_color1;
            background: rgba(220, 220, 220, .7);
            border-radius: 50%;
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            height: 20px;
            width: 20px;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            // background: $accent_color1;
            background: rgba(220, 220, 220, .7);
            border-radius: 50%;
        }
    }

    &__body {
        position: relative;
        width: 100%;
        height: 140px;
        border-radius: 3px 3px 0 0;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .slide {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform-origin: 0 0;
        }

        .slide-bg {
            opacity: 1;
        }
    }

    &__info {
        position: relative;
        display: flex;
        flex-grow: 0;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        border-top: 1px solid #ddd;
        background: #fff;
        padding: 6px 0 6px 20px;
        box-sizing: border-box;

        &-left {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            min-width: 0;
            justify-content: center;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &-right {
            position: relative;
            display: flex;
            width: 50px;
            flex-shrink: 0;
            align-items: center;
        }
    }

    &__title {
        position: relative;
        display: block;
        width: 100%;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
    }

    &__edited {
        position: relative;
        font-size: 11px;
        opacity: .4;
        font-weight: 500;
    }

    &__price {
        position: relative;
        display: block;
        width: 100%;
        padding: 0 10px 0 3px;
        font-size: 12px;
        opacity: .4;
        text-align: right;
    }

    &__nav-btn {
        position: relative;
        display: block;
        margin: 0 auto;
        height: 24px;
        width: 24px;
        cursor: pointer;
        // opacity: .5;

        &:hover svg {
            opacity: 1;
        }

        &:hover .presentation-preview__nav {
            display: block;
        }

        svg {
            position: relative;
            display: block;
            margin: 0 auto;
            opacity: .3;
        }
    }

    &__nav {
        position: absolute;
        // display: none;
        right: 0;
        bottom: 20px;
        // width: 100px;
        padding: 0;
        border-radius: 3px;
        background: #fff;

        .context-menu__item {
            padding: 12px 25px;

            &:hover {
                background: #F5F6F9;
            }
        }
    }

    .thumbs-bar {
        &__item {
            height: 100%;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
            margin-top: 0;
            border: none;
            pointer-events: none;
        }
    }

}
</style>

<i18n>
{
    "en": {
        "updated": "Updated",
        "view": "View",
        "rename": "Rename",
        "clone": "Clone",
        "archive": "Archive",
        "unarchive": "Unarchive",
        "delete": "Delete",
        "cancel": "Cancel"
    },
    "ru": {
        "updated": "Изменён",
        "view": "Просмотр",
        "rename": "Переименовать",
        "clone": "Клонировать",
        "archive": "В архив",
        "unarchive": "Разархивировать",
        "delete": "Удалить",
        "cancel": "Отмена"
    }
}
</i18n>
