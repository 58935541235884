export default [
    {
        id: 0,
        name: 'Free',
        priceM: 0,
        priceY: 0,
        features: {
            'Presentations': 3,
            'Storage space': '50mb',
            'Templates': true
        }
    },
    {
        id: 1,
        name: 'Gold',
        priceM: 10,
        priceY: 8,
        features: {
            'Presentations': 10,
            'Storage space': '500mb',
            'Templates': true,
            'Offline viewer': true,
            'Sharing': true,
            'Export to pdf': true
        }
    },
    {
        id: 2,
        name: 'Platinum',
        priceM: 15,
        priceY: 12,
        features: {
            'Presentations': 'Unlimited',
            'Storage space': '5Gb',
            'Templates': true,
            'Offline viewer': true,
            'Sharing': true,
            'Export to pdf': true,
            'Export to pptx': true,
            'Custom URLs': true
        }
    }
];
