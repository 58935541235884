<template>
    <div class="page__left-block">
        <div class="logo-wrapper">
            <router-link class="logo" to="/">
                <img src="@/assets/images/logo.png" alt="Logo" width="130px" height="28px"/>
            </router-link>
        </div>
        <div class="user-profile">
            <div class="user-profile__picture">
                <div class="user-profile__letter">{{ firstLetter }}</div>
            </div>
            <div class="user-profile__name">{{ user.name }}
                <div class="user-profile__email">{{ user.email }}</div>
            </div>
        </div>
        <div class="left-nav">
            <div
                class="left-nav__item"
                @click="$emit('screen', 'projects')"
                :class="{'left-nav__item_active': screen === 'projects'}"
            >
                <div class="left-nav__item-icon">
                    <svg width="18" height="17" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd">
                            <path d="M2 12.333V3M16 3v9.333"/>
                            <path stroke-linejoin="round" d="M1 1h16v2H1z"/>
                            <path d="M1 12h16M9 16v-3.667" stroke-linecap="round"/>
                        </g>
                    </svg>
                </div>
                <div class="left-nav__item-title">{{ $t('projects') }}</div>
            </div>

            <div
                class="left-nav__item"
                @click="$emit('screen', 'profile')"
                :class="{'left-nav__item_active': screen === 'profile'}"
            >
                <div class="left-nav__item-icon">
                    <svg width="16" height="14" xmlns="http://www.w3.org/2000/svg">
                        <g transform="translate(1 1)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd">
                            <path d="M0 2h2M0 10h8M7 2h7M13 10h1" stroke-linecap="round"/>
                            <circle cx="4" cy="2" r="2"/>
                            <circle cx="10" cy="10" r="2"/>
                        </g>
                    </svg>
                </div>
                <div class="left-nav__item-title">{{ $t('profile_settings') }}</div>
            </div>

            <router-link v-if="$store.state.user.isAdmin" class="left-nav__item" to="/manager">
                <div class="left-nav__item-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path stroke="#FFF" fill="#fff"
                              d="M224 16c-6.7 0-10.8-2.8-15.5-6.1C201.9 5.4 194 0 176 0c-30.5 0-52 43.7-66 89.4C62.7 98.1 32 112.2 32 128c0 14.3 25 27.1 64.6 35.9c-.4 4-.6 8-.6 12.1c0 17 3.3 33.2 9.3 48H45.4C38 224 32 230 32 237.4c0 1.7 .3 3.4 1 5l38.8 96.9C28.2 371.8 0 423.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-58.5-28.2-110.4-71.7-143L415 242.4c.6-1.6 1-3.3 1-5c0-7.4-6-13.4-13.4-13.4H342.7c6-14.8 9.3-31 9.3-48c0-4.1-.2-8.1-.6-12.1C391 155.1 416 142.3 416 128c0-15.8-30.7-29.9-78-38.6C324 43.7 302.5 0 272 0c-18 0-25.9 5.4-32.5 9.9c-4.7 3.3-8.8 6.1-15.5 6.1zm56 208H267.6c-16.5 0-31.1-10.6-36.3-26.2c-2.3-7-12.2-7-14.5 0c-5.2 15.6-19.9 26.2-36.3 26.2H168c-22.1 0-40-17.9-40-40V169.6c28.2 4.1 61 6.4 96 6.4s67.8-2.3 96-6.4V184c0 22.1-17.9 40-40 40zm-88 96l16 32L176 480 128 288l64 32zm128-32L272 480 240 352l16-32 64-32z"/>
                    </svg>
                </div>
                <div class="left-nav__item-title">{{ $t('manager') }}</div>
            </router-link>

            <router-link class="left-nav__item" @click.native="$root.reachGoal('clickLinkDocsFromAccountPage')"
                         to="/docs">
                <div class="left-nav__item-icon">
                    <svg
                        width="16"
                        height="16"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                        style="transform: translateX(-3px); fill: #fff;"
                        xml:space="preserve"
                    >
                            <circle cx="256" cy="378.5" r="25"/>
                        <path
                            d="M256,0C114.516,0,0,114.497,0,256c0,141.484,114.497,256,256,256c141.484,0,256-114.497,256-256 C512,114.516,397.503,0,256,0z M256,472c-119.377,0-216-96.607-216-216c0-119.377,96.607-216,216-216 c119.377,0,216,96.607,216,216C472,375.377,375.393,472,256,472z"/>
                        <path
                            d="M256,128.5c-44.112,0-80,35.888-80,80c0,11.046,8.954,20,20,20s20-8.954,20-20c0-22.056,17.944-40,40-40 c22.056,0,40,17.944,40,40c0,22.056-17.944,40-40,40c-11.046,0-20,8.954-20,20v50c0,11.046,8.954,20,20,20 c11.046,0,20-8.954,20-20v-32.531c34.466-8.903,60-40.26,60-77.469C336,164.388,300.112,128.5,256,128.5z"/>
                        </svg>
                </div>
                <div class="left-nav__item-title">{{ $t('help') }}</div>
            </router-link>

            <div class="left-nav__item left-nav__item_bottom" @click="logOut">
                <div class="left-nav__item-icon">
                    <svg width="16" height="17" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.411 8.998h5.69a.995.995 0 0 0 .996-1 1 1 0 0 0-.996-1h-5.69l2.607-2.595A1 1 0 0 0 4.604 2.99L.29 7.291a1 1 0 0 0 0 1.414l4.326 4.293a1 1 0 0 0 1.414-1.414L3.411 8.998zM6.974.006a1 1 0 0 0 0 2h6.515c.28 0 .504.224.504.503v11.034a.503.503 0 0 1-.504.503H6.974a1 1 0 0 0 0 2h6.515a2.504 2.504 0 0 0 2.504-2.503V2.509A2.503 2.503 0 0 0 13.49.006H6.974z"
                            fill="#FFF"
                            fill-rule="nonzero"/>
                    </svg>
                </div>
                <div class="left-nav__item-title">
                    {{ $t('log_out') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Sidebar',
    props: {
        screen: {
            type: String,
            default: 'projects'
        }
    },
    data () {
        return {};
    },
    computed: {
        ...mapState({
            user: state => state.user
        }),
        firstLetter () {
            return this.user.name.substr(0, 1).toUpperCase();
        }
    },
    methods: {
        logOut () {
            this.$store.dispatch('logout');
        }
    }
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/vars.scss';

.page__left-block {
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    width: 280px;
    display: flex;
    flex-shrink: 0;
    position: fixed;
    overflow: hidden;
    background: #212126;
    flex-direction: column;
    transition: all .3s ease;

    @media #{$mq-1200} {
        width: 250px;
    }

    .user-profile {
        position: relative;
        display: flex;
        cursor: default;
        padding: 10px 0 0 30px;
        align-items: center;

        &__picture {
            position: relative;
            display: block;
            flex-shrink: 0;
            height: 42px;
            width: 42px;
            background: rgba(0, 0, 0, .1);
            border-radius: 50%;
            border: 3px solid rgba(255, 255, 255, .1);
            box-sizing: border-box;
            margin-right: 10px;
        }

        &__letter {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-45%, -52%);
            font-weight: bold;
            opacity: 0.2;
            font-size: 20px;
            color: #fff;
        }

        &__name {
            color: #fff;
            text-transform: capitalize;
        }

        &__email {
            color: #979797;
            font-size: 12px;
        }

        &:hover {
            .user-nav {
                display: block;
            }
        }
    }
}

.logo-wrapper {
    display: flex;
    padding-left: 30px;
    align-items: center;
    height: $header-height;
}

.left-nav {
    display: flex;
    flex-grow: 1;
    user-select: none;
    padding: 70px 0 0;
    position: relative;
    flex-direction: column;

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 30px;
        height: 42px;
        flex-grow: 0;
        flex-shrink: 0;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, .05);
        color: rgba(255, 255, 255, .5);
        font-size: 11px;
        letter-spacing: 1px;
        font-weight: 700;
        border-left: 3px solid transparent;
        transition: all .15s ease;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;

        &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to right, $accent_color1 0%, $accent_color2 100%);
            z-index: 0;
            opacity: 0;
            transition: all .3s ease;
        }

        &:first-child {
            border-top: 1px solid rgba(255, 255, 255, .05);
        }

        &_bottom {
            margin-top: auto;
            background: rgba(0, 0, 0, .15);
            border-bottom: 0;
            // border-top: 1px solid rgba(255,255,255, .05);
        }

        &:hover {
            color: #fff;
            border-left: 3px solid rgba(255, 255, 255, .1);

            .left-nav__item-icon {
                opacity: 0.6;
            }
        }

        &_active, &_active:hover {
            color: #fff;
            border-left: 3px solid #82568E;

            &:before {
                opacity: .5;
            }

            .left-nav__item-icon {
                opacity: 1;
            }
        }

        &-icon {
            position: relative;
            display: flex;
            align-items: center;
            width: 20px;
            margin-right: 15px;
            margin-left: 10px;
            opacity: .3;
            transition: all .15s ease;

            svg {
                position: relative;
                display: block;
            }
        }

        &-title {
            display: block;
            position: relative;
            text-decoration: none;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "log_out": "Log out",
        "manager": "Manager",
        "projects": "Projects",
        "help": "Documentation",
        "profile_settings": "Profile settings"
    },
    "ru": {
        "log_out": "Выйти",
        "manager": "Админка",
        "help": "Документация",
        "projects": "Презентации",
        "profile_settings": "Профиль"
    }
}
</i18n>
