<template>
    <div id="manager">
        <DraggableHeader :component="components" @page="toogleComponent" @drag-done="refreshTable">
            <template slot="content">
                <UserTable
                    ref="UserTable"
                    v-if="components.users !== null"
                    v-show="components.users === true"
                />

                <PresentationTable
                    ref="PresentationTable"
                    v-if="components.presentations !== null"
                    v-show="components.presentations === true"
                />
            </template>
        </DraggableHeader>
    </div>
</template>

<script>
import { EventBus } from '@/main';
import UserTable from '@/components/manager/UserTable';
import DraggableHeader from '@/components/manager/DraggableHeader';
import PresentationTable from '@/components/manager/PresentationTable';

export default {
    name: 'Manager',
    components: { PresentationTable, UserTable, DraggableHeader },
    data: () => ({
        components: {
            users: true,
            presentations: null
        }
    }),
    methods: {
        findUser (id) {
            this.toogleComponent('users');
            this.$refs?.UserTable?.$refs?.table.setFilter({ id });
            this.$refs?.UserTable?.filterData({ name: 'id', value: id });
        },
        refreshTable () {
            if (this.components.users) {
                this.$refs?.UserTable.refresh();
            } else {
                this.$refs?.PresentationTable.refresh();
            }
        },
        toogleComponent (val) {
            Object.keys(this.components).forEach(el => {
                this.components[el] = false;
            });

            this.components[val] = true;
        }
    },
    created () {
        EventBus.$on('find-user', id => this.findUser(id));
    },
    beforeDestroy () {
        EventBus.$off('find-user');
    }
};
</script>

<style scoped lang="scss">
#manager {
    min-height: 100vh;
    padding-bottom: 30px;
}
</style>
