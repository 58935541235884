<template>
  <div class="plans-screen">
    <div class="plans-screen__message">Upgrade your account today to get more capabilities from the service.<br/>Choose
      the plan which suits your needs.
    </div>
    <div class="periods">
      <div class="period" v-bind:class="{'active': period=='m'}" v-on:click="period='m'">Monthly</div>
      <div class="period" v-bind:class="{'active': period=='y'}" v-on:click="period='y'">Yearly</div>
    </div>
    <div class="plans">
      <div class="plan" v-for="plan in plans">
        <div class="plan__title">{{ plan.name }}</div>
        <div class="plan__description">
          <div class="plan__description-item" v-for="(value, key) in plan.features">{{ key }}
            <span v-bind:class="{'true': value==true, 'false': value==false}">
              {{ (typeof value != 'boolean') ? value : '' }}
            </span>
          </div>
        </div>
        <div class="plan__bottom">
          <div class="plan__price" v-if="period=='m'">
            <div class="plan__currency">$</div>
            {{ plan.priceM }}
            <div class="plan__period">/ mo</div>
          </div>
          <div class="plan__price" v-if="period=='y'">
            <div class="plan__currency">$</div>
            {{ plan.priceY }}
            <div class="plan__period">/ mo</div>
          </div>
          <div class="btn" v-bind:class="{'current': currentPlan == plan.id}" v-if="currentPlan == plan.id">
            Current plan
          </div>
          <div class="btn" v-if="currentPlan != plan.id" v-on:click="$root.choosePlan(plan.id)">Choose plan</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import plans from '@/models/Plans';

export default {
  name: "Pricing",
  data(){
    return {
      plans,
      currentPlan: 0,
      period: 'y'
    };
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/common.scss';

.plans-screen{
  position: relative;
  display: flex;
  flex-direction:column;
  align-self:center;
  justify-content:center;
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;

  &__message{
    position: relative;
    display: block;
    padding: 18px 36px;
    margin: 24px 10px;
    color: #fff;
    text-align: center;
    background: linear-gradient(to right, #7D5690 0%, #FA8989 100%);
    border-radius: 3px;
  }
  .periods{
    position: relative;
    display: flex;
    flex-direction:row;
    justify-content:center;
    flex-shrink:0;
    // padding: 10px 0;

    .period{
      position:relative;
      display:block;
      padding: 3px 15px;
      margin: 0 5px;
      border-radius: 20px;
      font-size: 14px;
      color:$accent_color1;
      cursor: pointer;

      &:hover{
        background-color: rgba(0,0,0, .05);
      }

      &.active{
        background-color: $accent_color1;
        color:#fff;
      }
    }
  }
  .plans{
    position:relative;
    display:flex;
    padding: 20px 0;

    @media #{$mq-1200} {
      flex-direction:column;
    }
  }
  .plan{
    position:relative;
    display:flex;
    width: auto;
    flex-grow:1;
    flex-basis:0;
    flex-direction:column;
    flex-shrink:0;
    flex-wrap: wrap;
    padding: 30px;
    margin: 0 10px;
    background:#fff;
    border-radius: 4px;
    box-shadow: 0 0 14px 0 rgba(153, 154, 161, 0.25);

    @media (min-width: 480px) and (max-width: 1200px){
      flex-direction:row;
      flex-basis:auto;
      justify-content:space-between;
      margin:10px;
      align-items:flex-start;
    }

    @media (max-width: 480px){
      flex-basis:auto;
      margin-bottom: 20px;
    }

    &__title{
      position:relative;
      display: block;
      font-size: 24px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;

      @media (min-width: 480px) and (max-width: 1200px) {
        flex-grow:1;
        min-width: 138px;
      }
    }
    &__description{
      position:relative;
      display: block;
      padding: 10px 0;
      font-size: 14px;

      @media (min-width: 480px) and (max-width: 1200px) {
        flex-grow:3;
        padding: 0 40px;
        max-width: 400px;
      }

      &-item{
        position:relative;
        display:flex;
        justify-content:space-between;
        padding: 6px 0;
        color:#6D6C77;

        span{
          position:relative;
          display:block;
          padding-left: 50px;
          text-align: right;
          color:$space_gray;

          &.true{
            &:after{
              content: '';
              position:relative;
              display:block;
              height: 6px;
              width: 12px;
              border-left: 2px solid #8DE8A8;
              border-bottom: 2px solid #8DE8A8;
              transform:rotate(-45deg);
            }
          }
          &.false{
            position:relative;
            display:block;
            height: 2px;
            width: 12px;
            padding: 0;
            margin: 5px 0 0;
            transform:rotate(-45deg);
            background:#ddd;

            &:after{
              content: '';
              position:absolute;
              display:block;
              top:0;
              left:0;
              height: 100%;
              width: 100%;
              transform:rotate(90deg);
              background:#ddd;
            }
          }
        }
      }
    }
    &__bottom{
      position:relative;
      display:block;
      padding: 10px 0 0;
      border-top: 1px solid #eee;
      margin-top: auto;

      @media (min-width: 480px) and (max-width: 1200px) {
        margin-top: 0;
        padding-top: 0;
        border: 0;
      }
    }
    &__price{
      padding: 12px 0 24px;
      font-size: 32px;
      display:flex;
      align-items:center;

      @media (min-width: 480px) and (max-width: 1200px) {
        padding-top: 0;
      }
    }
    &__currency{
      font-size: 16px;
      margin-top:-10px;
    }
    &__period{
      font-size: 16px;
      opacity: .5;
      //align-self:flex-end;
      margin:12px 0 0 5px;
    }
    .btn{
      // cursor: pointer;
      &.current{
        cursor: default;
        background: none;
        color:$accent_color1;
        border: 2px solid $accent_color1;
      }
    }
  }
}
</style>
