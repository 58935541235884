<template>
  <div v-html="obj.html" :style="cssStyles" :class="obj.classList"></div>
</template>

<script>
/* eslint-disable */
export default {
  name: "AnimationObject",
  props: ['obj', 'slideSize', 'bgAndContentDifference'],
  computed: {
    translate() {
      let p = this.obj.params;
      if (p == null) {
        p = {
          tx: 0,
          ty: 0
        };
      }

      return {
        x: p.tx * (this.slideSize.w * this.bgAndContentDifference.x),
        y: p.ty * (this.slideSize.h * this.bgAndContentDifference.y)
      };
    },
    cssStyles() {
      let p = this.obj.params;
      const s = this.obj.styles;

      if (p == null) {
        p = {
          scale: 1,
          opacity: 1
        };
      }
      if (s.opacity == null) {
        s.opacity = 1;
      }
      if (s.mirror == null) {
        s.mirror = '1, 1';
      }
      if (s.rotate == null) {
        s.rotate = 0;
      }
      const mirror = s.mirror.split(', ').map(function(v) {
        return v * p.scale;
      }).join(', ');

      const res = {
        top: s.top + 'px',
        left: s.left + 'px',
        opacity: s.opacity * p.opacity,
        'z-index': s['z-index'],
        transform: `translate(${this.translate.x}px, ${this.translate.y}px) scale(${mirror}) rotate(${s.rotate}deg)`
      };

      if (s.height != null) {
        res.height = s.height + 'px';
      }
      if (s.width != null) {
        res.width = s.width + 'px';
      }

      return res;
    }
  }
};
</script>

<style>

</style>
