<template>
    <PageMobileOverlay v-if="isMob" />

    <div v-else class="page account-page">
        <Sidebar :screen="screen" @screen="val => screen = val"/>
        <div class="page__right-block" v-show="user">
            <div class="dialog projects-screen__dialog" v-if="!validBrowser && showUnsupportedBrowserDialog">
                <div class="dialog__window" @click.stop="">
                    <div class="dialog__header">{{ unsupportedBrowserText }}</div>
                    <div class="dialog__content">
                        <div class="flex-row dialog__actions">
                            <div class="btn" @click.prevent="showUnsupportedBrowserDialog = false">OK</div>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="errorText">
                <div class="account-page__important-message">
                    {{ $t(errorText) }}
                </div>
            </template>
            <template v-if="!errorText">
                <div class="account-page__important-message" v-if="user.verified === 0">
                    {{ $t('clickVerifyLink') }}
                </div>
                <div class="account-page__important-message" v-if="user.email_verified === 0 && user.verified === 1">
                    {{ $t('confirmationLinkSent') }} <a @click="changeEmailCancel">{{ $t('cancel') }}</a>
                </div>
            </template>
            <Projects v-show="screen === 'projects'" ref="projects"/>
            <div class="page__right-block-padding">
                <profile v-show="screen === 'profile'" ref="profile" :user="user"/>
                <billing v-show="screen === 'billing'" ref="billing"/>
                <pricing v-show="screen === 'pricing'"/>
                <newProject v-show="screen === 'create'"/>
            </div>
            <div class="dialog page__dialog" v-if="showSupportDialog" @click.prevent="showSupportDialog = false">
                <div class="dialog__window" @click.stop="">
                    <div class="dialog__header">{{ $t('support') }}</div>
                    <div class="dialog__content">
                        <form class="dialog__form" @submit.prevent="supportFormSubmit">
                            <textarea
                                class="dialog__textarea"
                                v-model="supportMsg"
                                :placeholder="$t('support_placeholder')"
                                @input="supportMsgTrim"
                            />
                            <label style="margin:10px 0 0">({{ supportMsg.length }} / {{ supportMsgMax }})</label>
                        </form>
                        <div class="flex-row dialog__actions">
                            <div class="btn btn__no-bg" @click.prevent="showSupportDialog = false">
                                {{ $t('cancel') }}
                            </div>
                            <div class="btn" @click.prevent="sendMessage">
                                {{ $t('submit') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog page__dialog" v-if="sendMessageSuccess" @click.prevent="sendMessageSuccess = false">
                <div class="dialog__window" @click.stop="">
                    <div class="dialog__header">{{ $t('sent') }}</div>
                    <div class="dialog__content">
                        {{ $t('msgSent') }}
                        <div class="flex-row dialog__actions">
                            <div class="btn" @click.prevent="sendMessageSuccess = false">Ok</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spinner spinner_bg_white" :class="{'spinner_active': busy}">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="#aaaaaa"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-loader"
                >
                    <path
                        d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>
                </svg>
            </div>
            <!-- ToDo: Вернуть техподдержку по надобности -->
            <div
                v-if="false"
                class="icon-btn icon-btn_support"
                @click="showSupportDialog = true"
                :class="{'icon-btn_hid':showSupportDialog}"
            >
                ?
            </div>
        </div>
    </div>
</template>

<script>
import Bowser from 'bowser';
import { mapState } from 'vuex';

import { apiPost, apiPut } from '@/models/Api';
import SupportedBrowsers from '@/models/SupportedBrowsers';

import Ajax from '@/mixins/Ajax';
import fonts from '@/mixins/fonts';
import common from '@/mixins/Common';
import Analytics from '@/mixins/Analytics';

import Sidebar from '@/components/account/Sidebar';
import Profile from '@/components/account/Profile';
import Billing from '@/components/account/Billing';
import Pricing from '@/components/account/Pricing';
import Projects from '@/components/account/Projects';
import NewProject from '@/components/account/NewProject';
import PageMobileOverlay from '@/components/PageMobileOverlay';

// import '../editor/components/presentation/presentation.scss';

const uaParser = Bowser.getParser(window.navigator.userAgent);
const browserName = uaParser.getBrowserName();
const validBrowser = uaParser.satisfies(SupportedBrowsers);

export default {
    name: 'Account',
    provide () {
        return {
            currentPage: this
        };
    },
    mixins: [Analytics, fonts, Ajax],
    components: {
        Sidebar,
        Projects,
        profile: Profile,
        billing: Billing,
        pricing: Pricing,
        PageMobileOverlay,
        // presentation: Presentation,
        newProject: NewProject
    },
    data () {
        return {
            who: 'account',
            isPage: true,
            newProjectSchemaVersion: 3,
            mousePressed: {
                left: false,
                right: false
            },
            token: '',
            screen: 'projects', // profile / billing / projects / pricing / create
            // user: this.$store.state.user,
            engine: common.detectEngine(),
            os: common.detectOs(),
            isMob: common.isMob(),
            validBrowser: validBrowser,
            errorText: '',
            busy: false,
            showSupportDialog: false,
            sendMessageSuccess: false,
            supportMsg: '',
            supportMsgMax: 1024,
            installer: {
                osName: '',
                url: '',
                size: 0
            },
            showUnsupportedBrowserDialog: true,
            unsupportedBrowserText: `Unfortunately, ${browserName} is not supported, app functions are severely limited. Please use one of the latest versions of Google Chrome or Microsoft Edge.`
        };
    },
    beforeCreate () {
        localStorage.setItem('isLoggedIn', true);
        const hash = window.location.hash;
        if (hash !== '' && hash !== '#') {
            const hashId = parseInt(window.location.hash.slice(1));
            if (!isNaN(hashId)) {
                localStorage.setItem('userId', hashId);
                window.location.hash = '';
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user
        }),
        firstLetter () {
            return this.user.name.substr(0, 1).toUpperCase();
        },
        quotaReached () {
            return this.user.projects_used >= this.user.projects_quota;
        }
    },
    async mounted () {
        // Мышь
        window.addEventListener('mousedown', (e) => {
            if (e.which === 1) {
                this.mousePressed = {
                    left: true,
                    right: this.mousePressed.right
                };
            }

            if (e.which === 3) {
                this.mousePressed.right = {
                    left: this.mousePressed.left,
                    right: true
                };
            }
        });

        window.addEventListener('mouseup', (e) => {
            if (e.which === 1) {
                this.mousePressed = {
                    left: false,
                    right: this.mousePressed.right
                };
            }

            if (e.which === 3) {
                this.mousePressed = {
                    left: this.mousePressed.left,
                    right: false
                };
            }
        });
    },
    methods: {
        supportMsgTrim () {
            if (this.supportMsg.length > this.supportMsgMax) {
                this.supportMsg = this.supportMsg.substring(0, this.supportMsgMax);
            }
        },
        async sendMessage () {
            this.busy = true;
            this.showSupportDialog = false;

            const res = await apiPost('/api/v1/support/send', { message: this.supportMsg });
            this.busy = false;

            if (res) {
                this.supportMsg = '';
                this.sendMessageSuccess = true;
            } else {
                this.showError('apiRequestError');
            }
        },
        async changeEmailCancel () {
            const res = await apiPut(`/api/v1/user/${this.$store.state?.user?.id}/email/cancel`);

            if (res) {
                this.$refs.profile.profile.email = this.user.email;
                await this.$store.commit('setUserParam', { key: 'emailVerified', value: 1 });
            }
        },
        async choosePlan (id) {
            if (!this.$refs.billing.card.displayNumber) {
                const data = {
                    id: id,
                    period: this.period
                };

                await apiPost(`/api/v1/user/${this.$store.state?.user?.id}/plan/update`, data);
                // ToDo: прикрутить обработку смены тарифы...
            } else {
                this.screen = 'billing';
                this.$refs.billing.changingCard = true;
            }
        },
        showError (error) {
            this.errorText = error;
            setTimeout(() => {
                this.errorText = '';
            }, 5000);
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/common.scss';

body {
    font-family: 'Montserrat', sans-serif;
    // ToDo: Удалить если ничего не сломалось по вёрстке после 01.11.2022
    //overflow: hidden;
}

.account-page {
    flex-direction: row;
    padding-top: 72px;
    box-sizing: border-box;
    min-width: 1024px;

    &__header {
        justify-content: flex-end;
        padding-right: 30px;
        align-items: stretch;
    }

    &__important-message {
        position: fixed;
        top: 0;
        left: 280px;
        display: block;
        width: calc(100% - 280px);
        box-sizing: border-box;
        text-align: center;
        background: $accent_color2;
        color: rgba(255, 255, 255, .8);
        padding: 10px 20px;
        font-size: 14px;
        z-index: 2;
        @media #{$mq-1200} {
            left: 250px;
            width: calc(100% - 250px);
        }

        a {
            position: relative;
            display: inline-block;
            color: #fff;
            border-bottom: 1px dotted #fff;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    .page__right-block {
        position: absolute;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        left: 280px;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 75px 0;
        // overflow: auto;
        overflow: hidden;

        @media #{$mq-1366} {
            padding: 0;
        }

        @media (max-height: 767px) {
            padding: 0;
        }

        @media #{$mq-1200} {
            left: 250px;
        }

        &-padding {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 60px;
            box-sizing: border-box;

            @media #{$mq-1366} {
                padding: 0;
            }

            @media (max-height: 767px) {
                padding: 0;
            }

        }
    }

    .page__dialog {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 280px;
        background: rgba(240, 240, 240, .97);
        z-index: 1;
    }

    .header__upgrade-link {
        position: relative;
        display: block;
        border-bottom: 2px solid $accent_color1;
        margin: 0 40px;

        @media #{$mq-1200} {
            margin: 0 30px;
        }
        @media #{$mq-480} {
            display: none;
        }
    }

    .user-profile {
        position: relative;
        display: flex;
        cursor: default;
        padding: 20px 0 0 30px;
        align-items: center;

        &__picture {
            position: relative;
            display: block;
            flex-shrink: 0;
            height: 42px;
            width: 42px;
            background: rgba(0, 0, 0, .1);
            border-radius: 50%;
            border: 3px solid rgba(255, 255, 255, .1);
            box-sizing: border-box;
            margin-right: 10px;
        }

        &__letter {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-45%, -52%);
            font-weight: bold;
            opacity: 0.2;
            font-size: 20px;
            color: #fff;
        }

        &__name {
            color: #fff;
            text-transform: capitalize;
        }

        &__email {
            color: #979797;
            font-size: 12px;
        }

        &:hover {
            .user-nav {
                display: block;
            }
        }
    }

    .profile__card-name {
        position: relative;
        display: inline-block;
        font-size: 18px;
        padding: 10px 0;
        margin-right: 30px;
        vertical-align: top;
    }

    .profile__card-number {
        position: relative;
        display: block;
        font-size: 14px;
        padding: 10px 0;
        color: #aaa;
    }

    .flex-row {
        position: relative;
        display: flex;
        align-items: stretch;
        // align-self: stretch;
        margin: 0 -5px;
    }

    .flex-col {
        position: relative;
        display: block;
    }

    .btn {
        min-height: 36px;
        box-sizing: border-box;
        padding-top: 12px;
        font-size: 11px;
        line-height: 11px;
        letter-spacing: 1px;
        font-weight: 700;
        text-transform: uppercase;

        &.current {
            padding-top: 10px;
        }
    }
}

.user-message {
    z-index: 20;
    position: absolute;
    display: block;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    text-align: center;
    background: #eee;
    color: $space_gray;
    padding: 10px 20px;
    font-size: 14px;
    transform: translateY(100%);
    opacity: 0;
    transition: all .3s ease;
    z-index: 1;

    &_active {
        transform: translateY(0);
        opacity: 1;
    }

    &_error {
        background: $accent_color2;
        color: rgba(255, 255, 255, .8);
    }

    &_success {
        background: #3ba776;
        color: rgba(255, 255, 255, .8);
    }
}

.btn_download-app {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 40px 10px 20px;
    background: linear-gradient(to right, $accent_color1 0%, $accent_color2 100%);
    text-transform: none;
    font-size: 16px;
    letter-spacing: 0;
    text-decoration: none;
    color: #fff !important;

    svg {
        position: relative;
        display: block;
        width: 16px;
        height: 19px;
        margin-right: 15px;
    }

    .btn__text {
        padding-left: 15px;
        border-left: 1px solid rgba(255, 255, 255, .4);
    }

    .btn__extra-text {
        font-size: 12px;
        font-weight: 400;
        text-transform: none;
        letter-spacing: 0;
        // opacity: .7;
        padding-top: 5px;
    }
}

.icon-btn {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    background: #0011;
    box-shadow: 0 4px 8px #ddd;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .3s ease;
    backface-visibility: hidden;

    &:hover {
        transform: scale(1.1);
    }

    &_hid {
        transform: scale(0.3);
        opacity: 0;
        pointer-events: none;
    }

    &_support {
        position: absolute;
        display: block;
        bottom: 30px;
        right: 30px;
        z-index: 1;
        padding-top: 12px;
        font-size: 16px;
        font-weight: 700;
        background: #fff;
        color: $accent_color1;

        &:hover {
            color: #fff;
            background: $accent_color1;
        }
    }
}

@import '@/assets/scss/editor/workspace.scss';
@import '@/assets/scss/preview.scss';
//@import '@/components/editor/SlideComponents/**/*.scss';

// Часть ниже взамен -> @import '@/components/editor/text/text.scss';
.text-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
}

.text {
    position: relative;
    display: block;
    width: 100%;
    // margin-top: auto;
    // margin-bottom: auto;
    max-width: 100%;
    max-height: 100%;
    min-width: 5px;
    outline: none;

    .text-selected & {
        background-color: #8881;
    }

    .p {
        position: relative;
    }

    .li {
        &:before {
            display: inline-block;
            content: attr(data-list-index);
            min-width: 1.3em;
        }
    }

    span {
        position: relative;
        white-space: pre-wrap;
        outline: none;
    }

    span::selection {
        color: inherit;
        background: rgba(200, 200, 222, 1);
    }
}
</style>

<i18n>
{
    "en": {
        "log_out": "Log out",
        "projects": "Projects",
        "profile_settings": "Profile settings",
        "support": "Support",
        "support_placeholder": "Enter your message here",
        "cancel": "Cancel",
        "submit": "Submit",
        "back": "Back",
        "sent": "Sent",
        "msgSent": "Your message has been successfully sent.<br/>We will send you a reply as soon as possible to the email you logged with.",
        "confirmationLinkSent": "We have sent a confirmation link to your email address. Please log in to your email account and click the link to verify new address or click",
        "clickVerifyLink": "We have sent a link to your email address. Please log in to your email account and click the link to verify your account.",
        "apiRequestError": "An error occurred. Please, try later"
    },
    "ru": {
        "log_out": "Выйти",
        "projects": "Презентации",
        "profile_settings": "Профиль",
        "support": "Помощь",
        "support_placeholder": "Введите ваше сообщение здесь",
        "cancel": "Отмена",
        "submit": "Отправить",
        "back": "Назад",
        "sent": "sent",
        "msgSent": "Ваше сообщение было успешно отправлено.<br>Мы отправим вам ответ как можно скорее на адрес электронной почты, который вы использовали для входа.",
        "confirmationLinkSent": "Мы отправили ссылку для подтверждения на ваш адрес электронной почты. Пожалуйста, войдите в свою учетную запись электронной почты и нажмите на ссылку, чтобы подтвердить новый адрес, или нажмите ",
        "clickVerifyLink": "Мы отправили ссылку на вашу электронную почту. Пожалуйста, войдите в свою почту и нажмите на ссылку, чтобы подтвердить свой аккаунт.",
        "apiRequestError": "Произошла ошибка. Пожалуйста, попробуйте позднее"
    }
}
</i18n>
