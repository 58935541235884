<template>
    <div class="create-screen">
        <div class="user-message" :class="msgClasses" @click="showMsg = false">
            {{ $t(message) }}
        </div>
        <div class="close-btn" @click.prevent="$parent.screen = 'projects'"></div>
        <div class="create-screen__left">
            <form class="create-form" @submit.prevent="">
                <div class="create-screen__title">{{ $t('newProject') }}</div>
                <div class="create-form__item">
                    <label>{{ $t('title') }}</label>
                    <input type="text" v-model="newProjectTitle"/>
                </div>
                <div class="create-form__item" v-if="selectedTemplate == 0" v-show="!newProjectIsSlideTemplate">
                    <label>{{ $t('size') }}</label>
                    <CustomSelect
                        :options="canvasSizes"
                        :prop_name="'newProjectSize'"
                        :default="0"
                        @selected="updateProp"
                        :isDisabled="newProjectIsSlideTemplate"
                    />
                    <div class="flex-row" v-if="newProjectSize.length == 0">
                        <div class="create-form__item">
                            <label>{{ $t('width') }}</label>
                            <input type="text" v-model="customWidthInput" @blur="customSizeBlur('width')"/>
                        </div>
                        <div class="create-form__item">
                            <label>{{ $t('height') }}</label>
                            <input type="text" v-model="customHeightInput" @blur="customSizeBlur('height')"/>
                        </div>
                    </div>
                </div>
                <template v-if="$store.state.user.isAdmin">
                    <div class="create-form__item">
                        <label>
                            <input type="checkbox" v-model="newProjectIsTemplate"/>
                            {{ $t('isTemplate') }}
                        </label>
                    </div>
                    <div class="create-form__item">
                        <label>
                            <input type="checkbox" v-model="newProjectIsSlideTemplate"/>
                            {{ $t('isSlideTemplate') }}
                        </label>
                    </div>
                </template>
                <div class="template__data" v-if="newProjectIsTemplate">
                    <div class="create-form__item">
                        <label>{{ $t('description') }}</label>
                        <textarea v-model="tplDescription"></textarea>
                    </div>
                    <div class="create-form__item">
                        <label>{{ $t('tags') }}</label>
                        <input type="text" v-model="tplTagInput" @keydown="tagsKeyDown"/>
                        <div class="tags">
                            <div class="tag" v-for="t in tplTags">{{ t }}
                                <div class="tag__delete" @click="deleteTag(t)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="create-form__item">
                        <label>{{ $t('price') }}</label>
                        <input type="text" v-model="tplPrice" @input="inputPrice"/>
                    </div>
                </div>
                <div class="template__data" v-if="newProjectIsSlideTemplate">
                    <div class="create-form__item">
                        <label>{{ $t('tags') }}</label>
                        <input type="text" v-model="tplTagInput" @keydown="tagsKeyDown"/>
                        <div class="tags">
                            <div class="tag" v-for="t in tplTags">{{ t }}
                                <div class="tag__delete" @click="deleteTag(t)"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="create-form__item">
                    <div class="btn" @click="newProject"
                         onclick="App.reachGoal('clickCreateNewPresentation'); return true;">
                        {{ $t('create') }}
                    </div>
                </div>
            </form>
        </div>
        <div v-if="!removeTemplates" class="create-screen__right">
            <div class="tabs">
                <div class="tabs__head">
                    <div class="tabs__padding">
                        <div class="tabs__head-item" :class="{active: createActiveTab == 'my'}"
                             @click="createActiveTab = 'my'; getUserTemplates()">
                            {{ $t('myTemplates') }}
                        </div>
                        <div
                            class="tabs__head-item"
                            v-if="$store.state.user.isAdmin"
                            :class="{active: createActiveTab === 'browse'}"
                            @click="createActiveTab = 'browse'; getTemplates()"
                        >
                            {{ $t('browseTemplates') }}
                        </div>
                        <div
                            class="tabs__head-item"
                            v-if="$store.state.user.isAdmin"
                            :class="{active: createActiveTab === 'slideTemplates'}"
                            @click="createActiveTab = 'slideTemplates'; getSlideTemplates()"
                        >
                            {{ $t('browseSlideTemplates') }}
                        </div>
                    </div>
                </div>
                <div class="tabs__body" @scroll="templatesScroll">
                    <div class="tabs__padding">
                        <div class="tabs__body-item" v-show="createActiveTab == 'my'">
                            <div class="presentations">
                                <div class="presentation-preview"
                                     v-bind:class="{'presentation-preview_selected': selectedTemplate == 0}"
                                     v-on:click="selectedTemplate = 0">
                                    <div class="presentation-preview__body"></div>
                                    <div class="presentation-preview__info">
                                        <div class="presentation-preview__info-left">
                                            <div class="presentation-preview__title">{{ $t('blank') }}</div>
                                        </div>
                                    </div>
                                </div>
                                <Presentation v-for="p in userTemplates" :key="p.id" :isMy="true" v-bind:opts="p"
                                              v-bind:selectedTemplate="selectedTemplate"/>
                            </div>
                        </div>
                        <div class="tabs__body-item" v-show="createActiveTab == 'browse'">
                            <div class="presentations">
                                <Presentation v-for="p in templates" :key="p.id" v-bind:opts="p"/>
                            </div>
                        </div>
                        <div class="tabs__body-item" v-show="createActiveTab == 'slideTemplates'">
                            <div class="presentations">
                                <Presentation v-for="p in slideTemplates" :key="p.id" v-bind:opts="p"/>
                            </div>
                        </div>
                        <div class="spinner tabs__spinner" :class="{'spinner_active': tabsBusy}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#aaaaaa"
                                 stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-loader">
                                <path
                                    d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TemplateInfo v-if="showTemplateInfo" :opts="templateToShow"/>
    </div>
</template>

<script>
import _ from 'lodash';
import JSZip from 'jszip';
import Ajax from '@/mixins/Ajax';
import Presentation from '@/components/account/Presentation';
import TemplateInfo from '@/components/account/TemplateInfo';
import CustomSelect from '@/components/CustomSelect';
import { apiDelete, apiPost } from '@/models/Api';

export default {
    name: 'NewProject',
    inject: ['currentPage'],
    components: {
        Presentation,
        TemplateInfo,
        CustomSelect
    },
    mixins: [Ajax],
    data () {
        return {
            removeTemplates: true,

            busy: false,
            tabsBusy: false,
            showTemplateInfo: false,
            templateToShow: {},
            loaded: false, // для первой загрузки шаблонов
            createActiveTab: 'my', // my / browse
            // all templates
            templates: [], // шаблоны, которые выбираем при создании (все)
            // user templates
            userTemplates: [], // шаблоны, которые выбираем при создании (которые пользователь добавил себе)
            // slide templates
            slideTemplates: [], // шаблоны слайдов, только для админов
            selectedTemplate: 0,
            // создание новой презентации
            requestItemsCount: 10, // количество шаблонов при запросе (take)
            newProjectTitle: '', // название новой презы
            newProjectSize: [1920, 1080], // размер презы при создании [w, h]
            newProjectIsTemplate: false,
            newProjectIsSlideTemplate: false,
            tplDescription: '',
            tplPrice: 0,
            tplTagInput: '',
            tplTags: [],
            customWidth: 1920,
            customHeight: 1080,
            canvasSizes: [
                {
                    caption: '1920 x 1080',
                    value: [1920, 1080]
                },
                {
                    caption: '1600 x 900',
                    value: [1600, 900]
                },
                {
                    caption: '1024 x 768',
                    value: [1024, 768]
                },
                {
                    caption: '800 x 600',
                    value: [800, 600]
                },
                {
                    caption: this.$t('custom'),
                    value: []
                }
            ],

            msgType: 'error', // error / warning / success
            message: 'Title is empty',
            showMsg: false
        };
    },
    watch: {
        'currentPage.screen' () {
            if ((this.currentPage.screen === 'create') && !this.loaded) {
                this.getUserTemplates();
            }
        },
        newProjectIsTemplate (val) {
            if (val) {
                this.newProjectIsSlideTemplate = false;
            }
        },
        newProjectIsSlideTemplate (val) {
            if (val) {
                this.newProjectIsTemplate = false;
                this.updateProp({ property: 'newProjectSize', value: this.canvasSizes[0] });
            }
        }
    },
    computed: {
        customWidthInput: {
            get () {
                const v = parseInt(this.customWidth);
                return Number(v);
            },
            set (val) {
                let v = parseInt(val);
                if (isNaN(v) || (v < 0)) {
                    v = this.customWidth;
                }
                this.customWidth = v;
                if (val.length > 0) {
                    this.$forceUpdate();
                }
            }
        },
        customHeightInput: {
            get () {
                const v = parseInt(this.customHeight);
                return v;
            },
            set (val) {
                let v = parseInt(val);
                if (isNaN(v) || (v < 0)) {
                    v = this.customHeight;
                }
                this.customHeight = v;
                if (val.length > 0) {
                    this.$forceUpdate();
                }
            }
        },
        msgClasses () {
            const c = ['user-message_' + this.msgType];
            if (this.showMsg) {
                c.push('user-message_active');
            }
            return c;
        }
    },
    methods: {
        customSizeBlur (param) {
            if (param === 'height') {
                if (this.customHeight < 600) {
                    this.customHeight = 600;
                }
                if (this.customHeight > 2160) {
                    this.customHeight = 2160;
                }
                this.customHeightInput = this.customHeight;
            } else {
                if (this.customWidth < 800) {
                    this.customWidth = 800;
                }
                if (this.customWidth > 4096) {
                    this.customWidth = 4096;
                }
                this.customWidthInput = this.customWidth;
            }

            this.$forceUpdate();
        },
        templatesScroll (e) {
            if ((e.target.clientHeight + e.target.scrollTop) >= e.target.scrollHeight) {
                if (this.createActiveTab === 'browse') {
                    this.getTemplates();
                } else {
                    this.getUserTemplates();
                }
            }
        },
        updateProp (opts) {
            if (this[opts.property] != null) {
                this[opts.property] = opts.value.value;
            }

            if (this[opts.property] === []) {
                this[opts.property] = [this.customWidth, this.customHeight];
            }
        },
        inputPrice (e) {
            let val = parseInt(e.target.value);
            if (isNaN(val) || (val < 0)) {
                val = 0;
            }
            this.tplPrice = val;
        },
        tagsKeyDown (e) {
            if ((e.keyCode === 13) && (e.target.value !== '') && (_.indexOf(this.tplTags, e.target.value) === -1)) {
                this.tplTags.push(e.target.value);
                this.tplTagInput = '';
            }
        },
        deleteTag (tag) {
            const idx = _.indexOf(this.tplTags, tag);

            if (idx !== -1) {
                this.tplTags.splice(idx, 1);
            }
        },
        getTemplateInfo (id, preview) {
            let tplArr;
            switch(this.createActiveTab) {
                case 'browse':
                    tplArr = this.templates;
                    break;
                case 'my':
                    tplArr = this.userTemplates;
                    break;
                case 'slideTemplates':
                    tplArr = this.slideTemplates;
                    break;
            }

            this.templateToShow = _.filter(tplArr, ['id', id])[0];

            if (!this.templateToShow) {
                return;
            }

            this.templateToShow.previewURL = preview;
            this.showTemplateInfo = true;
        },
        chooseTemplate (id, favorite) {
            if (favorite) {
                this.createActiveTab = 'my';
                this.showTemplateInfo = false;
                this.selectedTemplate = id;
            } else {
                this.addToFav(id, () => {
                    this.createActiveTab = 'my';
                    this.showTemplateInfo = false;
                    this.selectedTemplate = id;
                });
            }
        },
        async addToFav (id, cb) {
            if (cb == null && typeof cb !== 'function') {
                return;
            }

            const res = await apiPost(`/api/v1/templates/${id}/add`, {
                userId: this.$store.state.user.id
            });

            if (res) {
                const existingFavTemplate = this.userTemplates.find(t => t.id === id);

                if (existingFavTemplate == null) {
                    const newFavTemplate = this.templates.find(t => t.id === id);
                    this.userTemplates.push(_.cloneDeep(newFavTemplate));
                }

                this.$nextTick(() => cb());
            }
        },
        async removeFromFav (id) {
            const res = await apiDelete(`/api/v1/user${this.$store.state.user.id}/templates/${id}`);

            if (res) {
                const i = this.userTemplates.findIndex(t => t.id === id);
                this.userTemplates.splice(i, 1);
            }
        },
        async getTemplates () {
            this.tabsBusy = true;
            const res = await apiPost(
                '/api/v1/templates',
                {
                    take: this.requestItemsCount,
                    skip: this.templates.length
                }
            );
            this.tabsBusy = false;

            if (res) {
                const templates = _.uniqBy(this.templates.concat(res), 'id');
                this.templates = templates;
            }
        },
        // Пока убрали функцонал шаблонов убрал использование этой функции
        async getUserTemplates () {
            return;

            // this.tabsBusy = true;
            // const res = await apiPost(
            //     `/api/v1/user/${this.$store.state.user.id}/templates`,
            //     {
            //         take: this.requestItemsCount,
            //         skip: this.userTemplates.length
            //     }
            // );
            //
            // if(res) {
            //     const userTemplates = _.uniqBy(this.userTemplates.concat(res), 'id');
            //     this.userTemplates = userTemplates;
            // }
        },
        async getSlideTemplates () {
            const res = await apiPost(`/api/v1/user/slide_template`, { private: true });

            if (res) {
                const slideTemplatesArray = res.map(template => {
                    // eslint-disable-next-line camelcase
                    template.is_slideTemplate = true;
                    return template;
                });

                const slideTemplates = _.uniqBy(this.slideTemplates.concat(slideTemplatesArray), 'id');
                this.slideTemplates = slideTemplates;
            }
        },
        templateDeleted (id) {
            const idx = _.findIndex(this.templates, t => t.id === id);
            this.templates.splice(idx, 1);
            this.showTemplateInfo = false;
        },
        async newProject () {
            if (!this.newProjectTitle) {
                this.message = 'Enter project name';
                this.showMsg = true;
                return;
            }

            const prBody = {
                size: {
                    h: this.newProjectSize[1] != null ? this.newProjectSize[1] : this.customHeight,
                    w: this.newProjectSize[0] != null ? this.newProjectSize[0] : this.customWidth
                },
                schemaVersion: -1
            };

            if (this.newProjectIsSlideTemplate) {
                prBody.size.w = this.canvasSizes[0].value[0];
                prBody.size.h = this.canvasSizes[0].value[1];
            }

            const zip = new JSZip();
            await zip.file('presentation.base64', JSON.stringify(prBody));

            const base64 = await zip.generateAsync({
                type: 'base64',
                compression: 'DEFLATE',
                compressionOptions: {
                    level: 9
                }
            });

            let route = '/api/v1/presentation/create';
            const data = {
                name: this.newProjectTitle,
                // eslint-disable-next-line camelcase
                template_id: this.selectedTemplate
            };

            if (this.selectedTemplate === 0) {
                data.body = base64;
            }

            if (this.newProjectIsTemplate) {
                data.price = this.tplPrice;
                data.tags = JSON.stringify(this.tplTags);
                data.description = this.tplDescription;
                data.published = false;
                route = '/api/v1/template/create';
            }

            if (this.newProjectIsSlideTemplate) {
                data.tags = JSON.stringify(this.tplTags);
                data.published = false;
                route = '/api/v1/slide_template/create';
            }

            this.currentPage.busy = true;
            const res = await apiPost(route, data);
            this.currentPage.busy = false;

            if (res) {
                localStorage.setItem('isTemplate', this.newProjectIsTemplate);
                if (res.presentation_id != null) {
                    localStorage.setItem('presentationId', res.presentation_id);
                }

                if (res.template_id != null) {
                    localStorage.setItem('presentationId', res.template_id);
                }

                this.showMsg = false;

                if (this.currentPage.validBrowser) {
                    this.currentPage.gotoUrl('editor');
                    // this.$router.push('/editor');
                } else {
                    this.$router.push('/account');
                }
            }

            // return window.yaCounter53617321?.reachGoal?.('clickCreateNewPresentation');
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/common.scss';

.create-screen {
    position: relative;
    display: flex;
    flex-grow: 0;
    padding: 84px 0;
    width: 100%;
    max-height: calc(100vh - 285px);
    background: #fff;
    box-shadow: 0 0 15px rgba(41, 0, 41, .07);
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;

    @media #{$mq-1366} {
        padding: 96px 0 0;
        height: calc(100vh - 96px);
        max-height: none;
    }

    @media (max-height: 767px) {
        padding: 96px 0 0;
        height: calc(100vh - 96px);
        max-height: none;
    }

    // @media #{$mq-1200} {
    //   padding: 36px 0;
    // }

    .close-btn {
        position: absolute;
        display: block;
        top: 50px;
        right: 40px;
        z-index: 1;
    }

    .pages, .presentations {
        padding: 0;
        margin-bottom: 0;
    }

    .presentation-preview {
        @media #{$mq-1280} {
            // width: 100%;
            height: auto;
        }
    }

    &__title {
        font-size: 32px;
        margin-bottom: 36px;
        font-weight: 500;
    }

    .create-form {
        position: relative;
        display: block;
        width: 100%;
        padding-bottom: 20px;

        &__item {
            position: relative;
            display: block;
            padding: 10px 0;
        }

        .btn {
            margin-top: 20px;
        }

        .flex-row {
            .create-form__item {
                padding: 10px 5px;
            }
        }
    }

    &__left, &__right {
        position: relative;
        display: block;
        padding: 0 80px;
        width: 100%;
        box-sizing: border-box;

        @media #{$mq-1600} {
            padding: 0 50px;
        }
        @media #{$mq-1440} {
            padding: 0 40px;
        }
        @media #{$mq-1200} {
            padding: 0 30px;
        }
    }

    &__left {
        display: flex;
        width: 400px;
        box-sizing: border-box;
        border-right: 1px solid #eee;
        flex-shrink: 0;
        overflow: auto;

        @media #{$mq-1440} {
            width: 300px;
        }

        // @media (max-width: 1279px) {
        //   border: none;
        // }
    }

    &__right {
        flex-grow: 1;
    }

    .tabs__head {
        background: none;
        font-weight: 500;
        border-bottom: 2px solid #eee;
        // box-shadow: 0 4px 8px #ffffff;
        // z-index: 1;
        .tabs__padding {
            margin-bottom: -2px;
        }
    }

    .tabs__padding {
        max-width: none;
        position: relative;
        box-sizing: border-box;
    }

    .tabs__body {
        position: relative;
        display: block;
        padding: 40px 20px 0;
        margin: 0 -40px 0 -20px;
        box-sizing: border-box;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #eee;
        }

        &::-webkit-scrollbar-thumb {
            background: $accent_color1;
        }

        &-item {
            position: relative;
            display: block;
            box-sizing: border-box;
        }

        .tabs__padding {
            padding-bottom: 40px;
        }

        .spinner {
            top: auto;
            height: 35px;
        }
    }

    .tags {
        margin-top: 10px;
    }

    .tag {
        position: relative;
        display: inline-block;
        font-size: 12px;
        padding: 3px 20px 3px 5px;
        border-radius: 3px;
        margin: 0 5px 5px 0;
        background: #eee;

        &__delete {
            position: absolute;
            display: block;
            right: 5px;
            top: 9px;
            height: 2px;
            width: 10px;
            background: #333;
            transform: rotate(45deg);
            cursor: pointer;
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }

            &:before {
                content: '';
                height: 18px;
                width: 18px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                display: block;
                // background:rgba(255,255,255, .1);
                border-radius: 50%;
            }

            &:after {
                content: '';
                height: 100%;
                width: 100%;
                position: absolute;
                display: block;
                transform: rotate(90deg);
                background: #333;
            }
        }
    }

    textarea {
        position: relative;
        display: block;
        min-width: 0;
        min-height: 0;
        width: 100%;
        height: 150px;
        margin-top: 10px;
        resize: none;
        border-color: #aaa;
        box-sizing: border-box;
        border-radius: 3px;

        &:focus {
            outline: none;
            border-color: #777;
        }
    }
}

// ToDo: пока скрыл работу с шаблонами
.create-screen__left {
    width: 100%;
    max-width: 650px;
    border: none;
    margin: 0 auto;
}
</style>

<i18n>
{
    "en": {
        "newProject": "New project",
        "title": "Title",
        "size": "Size",
        "width": "Width",
        "height": "Height",
        "isTemplate": "Is template",
        "isSlideTemplate": "Is slide template",
        "description": "Description",
        "tags": "Tags",
        "price": "Price",
        "create": "Create",
        "custom": "Custom",
        "myTemplates": "My templates",
        "browseTemplates": "Browse templates",
        "browseSlideTemplates": "Browse slide templates",
        "blank": "Blank",
        "Enter project name": "Enter project name",
        "Title is empty": "Title is empty"
    },
    "ru": {
        "newProject": "Новая презентация",
        "title": "Название",
        "size": "Размер",
        "width": "Ширина",
        "height": "Высота",
        "isTemplate": "Шаблон",
        "isSlideTemplate": "Шаблон слайда",
        "description": "Описание",
        "tags": "Тэги",
        "price": "Цена",
        "create": "Создать",
        "custom": "Свой",
        "myTemplates": "Мои шаблоны",
        "browseTemplates": "Шаблоны",
        "browseSlideTemplates": "Шаблоны слайдов",
        "blank": "Пустой",
        "Enter project name": "Введите название презентации",
        "Title is empty": "Название не задано"
    }
}
</i18n>
