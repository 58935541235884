<template>
    <div class="profile-settings profile-settings_billing">
        <div class="flex-col">
            <div class="profile-settings__title">Billing information</div>
            <form v-if="!changingCard">
                <div class="profile-settings__item profile-settings__item_underline profile-settings__item_flex">
                    <div class="profile-plan"><label>Your plan</label>{{ plan }}</div>
                    <div class="btn btn_to-right" v-if="plan == 'Free'" v-on:click="currentPage.screen = 'pricing'">
                        Upgrade
                    </div>
                </div>
                <div class="profile-settings__item profile-settings__item__underline" v-if="card.displayNumber"><label>Payment
                    method</label>
                    <div class="profile__card-name">{{ card.firstName }} {{ card.lastName }}
                        <div class="profile__card-number">{{ card.displayNumber }}</div>
                    </div>
                    <div class="btn" v-on:click="changingCard = true">Change card</div>
                </div>
            </form>
            <form v-if="changingCard">
                <div class="flex-row">
                    <div class="profile-settings__item">
                        <label>First name</label>
                        <input type="text" v-model="card.firstName"/>
                    </div>
                    <div class="profile-settings__item">
                        <label>Last name</label>
                        <input type="text" v-model="card.lastName"/>
                    </div>
                </div>
                <div class="flex-row">
                    <div class="profile-settings__item">
                        <label>Card number</label>
                        <input type="text" v-model="card.cardNumber"/>
                    </div>
                </div>
                <div class="flex-row">
                    <div class="profile-settings__item"><label>CVV</label><input type="text" v-model="card.cvv"/></div>
                    <div class="profile-settings__item"><label>Expires on</label>
                        <div class="flex-row"><input type="text" v-model="card.expires.month" placeholder="MM"/><input
                            type="text" v-model="card.expires.year" placeholder="YYYY"/></div>
                    </div>
                </div>
                <div class="flex-row">
                    <div class="profile-settings__item"><label>Zip</label><input type="text" v-model="card.zip"/></div>
                    <div class="profile-settings__item"><label>Country</label><select v-model="card.country">
                        <option selected="selected" disabled="disabled">Select your country</option>
                        <option>Angola</option>
                        <option>Belgium</option>
                        <option>Cuba</option>
                    </select></div>
                </div>
                <div class="profile-settings__item">
                    <div class="btn" v-on:click="updateCard">Add card</div>
                    <div class="btn btn__no-bg" v-on:click="changingCard = false">Cancel</div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import Ajax from '@/mixins/Ajax';
import { apiPost } from '@/models/Api';

export default {
    name: 'Billing',
    mixins: [Ajax],
    inject: ['currentPage'],
    data () {
        return {
            plan: 'Free',
            card: {
                type: '',
                firstName: '',
                lastName: '',
                displayNumber: '',
                cardNumber: '',
                cvv: '',
                expires: {
                    month: '',
                    year: ''
                },
                zip: '',
                country: ''
            },

            changingEmail: false,
            changingPass: false,
            changingCard: false,

            message: false,
            errors: {
                name: [],
                email: [],
                password: [],
                error: []
            }
        };
    },
    watch: {
        changingPass () {
            if (this.changingPass) {
                this.password = '';
                // eslint-disable-next-line camelcase
                this.password_new = '';
                // eslint-disable-next-line camelcase
                this.password_new_confirmation = '';
            }
        },
        activeTab () {
            if (this.activeTab === 'profile') {
                this.changingCard = false;
            }
            if (this.activeTab === 'billing') {
                this.changingPass = false;
            }
        }
    },
    created () {
        this.getUserData();
        this.user = this.$store.state.user;
    },
    methods: {
        compareEmails () {
            if (this.user.email === this.profile.email) {
                this.changingEmail = false;
            } else {
                this.changingEmail = true;
            }
        },
        getUserData () {
        },
        async setUserData () {
            const data = {};
            if (this.user.name !== this.profile.name) {
                data.name = this.profile.name;
            }

            if (this.changingEmail) {
                data.email = this.profile.email;
                data.password = this.profile.password;
            }

            if (Object.keys(data).length === 0) {
                return;
            }

            const res = await apiPost(`/api/v1/user/${this.$store.state.user.id}/update`);

            if (res) {
                _.each(data, (v, k) => {
                    this.user[k] = v;
                    if (k === 'email') {
                        this.changingPass = false;
                    }
                });
            }
        },
        async updatePass () {
            const res = await apiPost(
                `/api/v1/user/${this.currentPage.user.id}/password/update`,
                {
                    password: this.profile.password,
                    // eslint-disable-next-line camelcase
                    password_new: this.profile.password_new,
                    // eslint-disable-next-line camelcase
                    password_new_confirmation: this.profile.password_new_confirmation
                }
            );

            if (res) {
                this.changingPass = false;
            }
        },
        updateCard () {
            let route;
            if (this.profile.card.displayNumber === '') {
                route = '/api/v1/user/${this.$store.state.user.id}/card/store';
            } else {
                route = '/api/v1/user/${this.$store.state.user.id}/card/update';
            }

            const res = apiPost(route, { card: this.profile.card });

            if (res) {
                this.changingPass = false;

                if (this.pendingFunction != null && typeof this.pendingFunction === 'function') {
                    this.pendingFunction();
                }
            }
        }
    }
};
</script>

<style lang="scss">
.profile-settings_billing {
    max-width: 960px;

    .flex-row {
        margin: 0 -10px;

        .profile-settings__item {
            padding-left: 10px;
            padding-right: 10px;

            .flex-row {
                margin: 0;
            }
        }

        > input[type = "text"] {
            &:first-child {
                margin-right: 20px;
            }
        }
    }

    .profile-plan {
        position: relative;
        display: inline-block;
        font-size: 18px;
        padding: 10px 0;
        margin-right: 30px;

        label {
            margin-bottom: 10px;
        }
    }
}
</style>
