<template>
    <div class="projects-screen" @scroll="projectsScroll($event)">
        <div v-if="!noProjects || currentPage.validBrowser" class="projects-screen__content">
            <SearchInput
                class="mb-3"
                @search="search => $router.push(`/presentation-search?search=${search}`)"
            />

            <div class="presentation-tabs">
                <div
                    class="presentation-tabs__item"
                    v-bind:class="{'presentation-tabs__item__active': activeTab === 'presentations'}"
                    v-on:click="activeTab = 'presentations'; getPresentations(true)"
                >
                    {{ $t('my_projects') }}
                    <span class="presentations-total">
                        ({{ presentationsTotal }})
                    </span>
                </div>
                <div class="presentation-tabs__split"></div>
                <div
                    class="presentation-tabs__item"
                    v-bind:class="{'presentation-tabs__item__active': activeTab === 'archive'}"
                    v-on:click="activeTab = 'archive'; getArchive(true)"
                >
                    {{ $t('archived') }}
                    <span class="presentations-total">({{ archivedTotal }})</span>
                </div>
            </div>
            <div class="presentations" v-if="activeTab === 'presentations'">
                <div
                    class="presentations__new-project"
                    v-if="currentPageNumber === 1 && currentPage.validBrowser"
                    v-on:click.prevent="newProject()"
                    :class="{'presentations__new-project_blocked': currentPage.quotaReached}"
                >
                    <div class="plus"></div>
                    <span>{{ $t('new_project') }}</span>
                </div>
                <Presentation v-for="(p) in presentations" :key="p.id" v-bind:opts="p"/>
            </div>
            <div class="pages" v-if="activeTab === 'presentations'">
                <div
                    class="pages__item"
                    v-for="p in pages"
                    :class="{'pages__item__active': p === currentPageNumber}"
                    @click="getPresentations(p)"
                >
                    {{ p }}
                </div>
            </div>
            <div class="presentations" v-if="activeTab === 'archive'">
                <Presentation v-for="(p) in archived" :key="p.id" v-bind:opts="p"/>
            </div>
            <div class="pages" v-if="activeTab === 'archive'">
                <div
                    v-for="p in archivePages"
                    class="pages__item"
                    :class="{'pages__item__active': p === archiveCurrentPage}"
                    @click="getArchive(p)">
                    {{ p }}
                </div>
            </div>
        </div>
        <div class="spinner spinner_bottom" :class="{'spinner_active': loadingItems}">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#aaaaaa" stroke-width="2"
                 stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                <path
                    d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>
            </svg>
        </div>
        <div class="dialog projects-screen__dialog" v-if="showDeleteDialog" @click="showDeleteDialog = false">
            <div class="dialog__window" @click.stop="">
                <div class="dialog__header">{{ $t('delete_question') }}</div>
                <div class="dialog__content">
                    <div class="flex-row dialog__actions">
                        <div class="btn btn__no-bg" @click="showDeleteDialog = false">{{ $t('cancel') }}</div>
                        <div class="btn" @click="deleteItem">{{ $t('delete') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog projects-screen__dialog" v-if="showRenameDialog" @click.prevent="showRenameDialog = false">
            <div class="dialog__window" @click.stop="">
                <div class="dialog__header">{{ $t('enterNewProjectName') }}</div>
                <div class="dialog__content">
                    <form class="dialog__form" @submit.prevent="renameItem">
                        <input class="dialog__input_text" type="text" v-model="newProjectName"/>
                    </form>
                    <div class="flex-row dialog__actions">
                        <div class="btn btn__no-bg" @click.prevent="showRenameDialog = false">{{ $t('cancel') }}</div>
                        <div class="btn" @click.prevent="renameItem">{{ $t('rename') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="spinner spinner_bg_white" :class="{'spinner_active': busy}">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#aaaaaa" stroke-width="2"
                 stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                <path
                    d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>
            </svg>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';

import Ajax from '@/mixins/Ajax';
import Presentation from '@/components/account/Presentation';
import { apiDelete, apiGet, apiPost, apiPut } from '@/models/Api';
import SearchInput from '@/components/SearchPresentations/SearchInput.vue';

export default {
    name: 'Projects',
    components: {
        SearchInput,
        Presentation
    },
    inject: ['currentPage'],
    mixins: [Ajax],

    data () {
        return {
            activeTab: 'presentations', // presentations / archive
            // presentations
            presentations: [], // превью презентаций пользователя на текущей странице
            presentationsTotal: 0, // их количество (всего)
            pages: [], // страницы блока с превью презентаций
            currentPageNumber: 1, // текущая страница блока
            // archive
            archived: [], // превью архивных презентаций пользователя на текущей странице
            archivedTotal: 0, // их количество (всего)
            archivePages: [], // страницы блока архивных превью
            archiveCurrentPage: 1, // текущая страница блока
            busy: false,
            loadingItems: false,
            showDeleteDialog: false,
            showRenameDialog: false,
            newProjectName: '',
            selectedItem: 0,
            takeItems: 10,
            loaded: false
        };
    },

    computed: {
        noProjects () {
            if (this.currentPage.user?.role === 1) {
                return false;
            }

            return this.loaded && (this.presentationsTotal === 0) && (this.archivedTotal === 0);
        }
    },
    mounted () {
        this.$nextTick(() => this.getPresentations());
    },

    methods: {
        findProject (array, id, cb) { // найти в array элемент по id и сделать с ним cb
            const uid = _.findIndex(array, p => p.id === id); // array.filter((p)->p.id == id)[0]
            if ((uid > -1) && (typeof cb === 'function')) {
                // console.log 'process cb with ', uid
                cb(uid);
            } else {
            }
        },
        async renameItem (id, currentName) {
            if (!this.showRenameDialog) {
                this.selectedItem = id;
                this.newProjectName = currentName;
                this.showRenameDialog = true;
                return;
            }

            this.busy = true;
            this.showRenameDialog = false;
            const res = await apiPut(`/api/v1/presentation/${this.selectedItem}`, { name: this.newProjectName });
            this.busy = false;

            if (res) {
                this.findProject(this.presentations, this.selectedItem, foundId => {
                    this.presentations[foundId].name = this.newProjectName;
                });
            }
        },
        async cloneItem (id) {
            if (!this.currentPage.quotaReached) {
                this.busy = true;

                const res = await apiPost(`/api/v1/presentation/${id}/clone`);
                this.busy = false;

                if (res) {
                    this.presentations = [];
                    await this.getPresentations();

                    await this.$store.commit('setUserParam', {
                        key: 'projectsUsed',
                        value: this.$store.state.user.projectsUsed + 1
                    });
                }
            } else {
                this.currentPage.showError('Presentations quota reached. Delete existing presentation or upgrade your billing plan.');
            }
        },
        async deleteItem (id) {
            if (!this.showDeleteDialog) {
                this.showDeleteDialog = true;
                this.selectedItem = id;
                return;
            }
            this.showDeleteDialog = false;

            this.busy = true;
            const res = await apiDelete(`/api/v1/presentation/${this.selectedItem}`);
            this.busy = false;

            if (res) {
                if (this.activeTab === 'archive') {
                    this.findProject(this.archived, this.selectedItem, foundId => {
                        this.archived.splice(foundId, 1);
                        this.archivedTotal--;
                    });
                } else {
                    this.findProject(this.presentations, this.selectedItem, foundId => {
                        this.presentations.splice(foundId, 1);
                        this.presentationsTotal--;
                    });
                }

                await this.$store.commit('setUserParam', {
                    key: 'projectsUsed',
                    value: this.$store.state.user.projectsUsed - 1
                });
            }
        },
        async archiveItem (id) {
            this.busy = true;

            const res = await apiPost(`/api/v1/presentation/${id}/archive`);
            this.busy = false;

            if (res) {
                this.findProject(this.presentations, id, (foundItem) => {
                    this.presentations.splice(foundItem, 1);
                    this.archived = [];
                    this.archivedTotal++;
                    this.presentationsTotal--;
                });
            }
        },
        async unarchiveItem (id) {
            this.busy = true;
            const res = await apiDelete(`/api/v1/presentation/${id}/archive`);
            this.busy = false;

            if (res) {
                this.findProject(this.archived, id, foundItem => {
                    this.archived.splice(foundItem, 1);
                    this.presentations = [];
                    this.archivedTotal--;
                    this.presentationsTotal++;
                });
            }
        },
        async getPresentations (doNotLoadIfHaveSome) {
            if (doNotLoadIfHaveSome && (this.presentations.length > 0)) {
                return;
            }

            const res = await apiGet(
                '/api/v1/presentations',
                {
                    skip: this.presentations.length,
                    take: this.takeItems,
                    archived: 0
                }
            );

            if (res && res?.items != null) {
                const presentations = _.uniqBy(this.presentations.concat(res.items), 'id');

                const noNewItems = presentations.length === this.presentations.length;
                this.presentations = presentations;
                this.presentationsTotal = res.presentations_total;
                this.archivedTotal = res.archive_total;
                this.loaded = true;

                this.$nextTick(() => {
                    if (noNewItems) {
                        return;
                    }

                    // Равенство выполнится если нет скролла
                    const itemsContainer = this.$el;
                    if (itemsContainer.scrollHeight === itemsContainer.clientHeight) {
                        this.getPresentations();
                    }
                });
            }
        },
        async getArchive (doNotLoadIfHaveSome) {
            if (doNotLoadIfHaveSome && (this.archived.length > 0)) {
                return;
            }

            const res = await apiGet(
                '/api/v1/presentations',
                {
                    skip: this.archived.length,
                    take: this.takeItems,
                    archived: 1
                }
            );

            if (res && res?.items != null) {
                const archived = _.uniqBy(this.archived.concat(res.items), 'id')
                ;
                const noNewItems = archived.length === this.archived.length;
                this.archived = archived;
                this.presentationsTotal = res.presentations_total;
                this.archivedTotal = res.archive_total;

                this.$nextTick(() => {
                    if (noNewItems) {
                        return;
                    }

                    // Равенство выполнится если нет скролла
                    const itemsContainer = this.$el;
                    if (itemsContainer.scrollHeight === itemsContainer.clientHeight) {
                        this.getArchive();
                    }
                });
            }
        },
        projectsScroll (e) {
            const pb = parseInt(getComputedStyle(e.target)['padding-bottom']);

            if (($(e.target).height() + e.target.scrollTop) >= (e.target.scrollHeight - pb)) {
                switch(this.activeTab) {
                    case 'presentations':
                        this.getPresentations();
                        break;
                    case 'archive':
                        this.getArchive();
                        break;
                }
            }
        },
        newProject () {
            if (!this.currentPage.quotaReached) {
                this.$parent.screen = 'create';
            } else {
                this.currentPage.showError('Presentations quota reached. Delete existing presentation or upgrade your billing plan.');
            }
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/common.scss';

.projects-screen {
    // position:relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    overflow: auto;
    position: absolute;

    &__content {
        padding: 60px 60px 0 80px;

        @media #{$mq-1280} {
            padding: 80px 0 0 60px;
        }
        @media #{$mq-1200} {
            padding: 80px 0 0 35px;
        }
    }

    .spinner_bottom {
        top: auto;
        height: 30px;
        overflow: hidden;
    }

    &__dialog {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 280px;
        background: rgba(240, 240, 240, .97);
        z-index: 1;
    }

    &__default-content {
        position: relative;
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__default-text {
        font-weight: 500;
        font-size: 20px;
        padding: 20px 20px 40px;
    }

    &__web-version-text {
        font-weight: 500;
        font-size: 16px;
        padding: 40px 20px 20px;
    }

}

.presentation-tabs {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 40px;

    // @media #{$mq-1024} {
    //   justify-content:center;
    // }

    .presentations-total {
        font-size: 16px;
        opacity: .3;
        vertical-align: middle;
    }

    &__item {
        position: relative;
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: #aaa;
        cursor: pointer;

        &:hover {
            color: #888;
        }

        &__active {
            color: $space_gray;
            font-size: 32px;
            top: -2px;
            cursor: default;

            &:hover {
                color: $space_gray;
            }
        }
    }

    &__split {
        position: relative;
        display: block;
        height: 16px;
        width: 1px;
        background: #ddd;
        margin: 0 30px;
    }
}

.presentations {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    // @media #{$mq-1024} {
    //   justify-content:center;
    // }

    &__new-project {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 280px;
        height: 190px;
        margin: 0 20px 20px 0;
        cursor: pointer;
        border-radius: 5px;
        overflow: hidden;
        border: 3px dashed #DBDCE0;
        box-sizing: border-box;
        stroke-dasharray: 15px;
        font-weight: 500;

        &:hover {
            border-color: #BFC2C8;

            span {
                opacity: 1;
            }
        }

        .plus {
            position: relative;
            display: block;
            height: 24px;
            width: 24px;
            background: $accent_color1;
            border-radius: 50%;
            margin-right: 10px;

            &:before, &:after {
                position: absolute;
                display: block;
                content: '';
                height: 2px;
                width: 10px;
                background: #fff;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }

        &_blocked {
            .plus {
                background: #eee;
            }
        }

        span {
            position: relative;
            display: block;
            opacity: .5;
            font-size: 20px;
        }
    }

    .presentation-preview {
        position: relative;
        display: block;
    }
}

.pages {
    position: relative;
    display: flex;
    margin: 24px 0;
    color: #aaa;

    @media #{$mq-1024} {
        justify-content: center;
    }

    &__item {
        position: relative;
        display: block;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        padding: 12px 0;
        margin-right: 5px;
        box-sizing: border-box;
        cursor: pointer;
        opacity: .3;
        color: $space_gray;
        text-align: center;
        font-size: 13px;
        font-weight: 700;

        &:hover {
            opacity: 1;
            background: rgba(0, 0, 0, 0.05);
        }

        &__active {
            opacity: 1;
            color: #fff;
            background: $accent_color1;

            &:hover {
                background: $accent_color1;
                cursor: default;
            }
        }
    }
}
</style>

<i18n>
{
    "en": {
        "my_projects": "My projects",
        "archived": "Archived",
        "new_project": "New project",
        "delete_question": "Delete this project permanently?",
        "cancel": "Cancel",
        "delete": "Delete",
        "rename": "Rename",
        "enterNewProjectName": "Enter new project name"
    },
    "ru": {
        "my_projects": "Мои презентации",
        "archived": "Архив",
        "new_project": "Новая презентация",
        "delete_question": "Удалить презентацию безвозвратно?",
        "cancel": "Отмена",
        "delete": "Удалить",
        "rename": "Переименовать",
        "enterNewProjectName": "Введите новое название призентации"
    }
}
</i18n>
