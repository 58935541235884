<template>
  <div class="slide-bg" :style="bgStyles">
    <div class="slide-bg__color" :style="scaledBg.colorStyles"></div>
    <div class="slide-bg__image" v-if="scaledBg.imageStyles" :style="scaledBg.imageStyles"></div>
  </div>
</template>

<script>
/* eslint-disable */
import _ from "lodash";
import Bg from "@/mixins/editor/Bg";
export default {
  name: "AnimationSlide",
  props: ['bg', 'slideWidth', 'slideHeight', 'diff'],
  mixins: [Bg],
  data() {
    return {
      mountedFlag: false
    };
  },
  mounted() {
    this.mountedFlag = true;
  },
  computed: {
    slideEars() {
      let diff, hor, rh, rw, sh, sw, ver;
      if (!this.mountedFlag) {
        return;
      }

      // ToDo: закоментировал странное место
      // this.$root.resizeFlag;

      const presentationRect = this.$el.getBoundingClientRect();
      const prw = presentationRect.width;
      const prh = presentationRect.height;
      const rectRatio = prw / prh;
      const slideWidth = this.slideWidth;
      const slideHeight = this.slideHeight;
      const slideRatio = slideWidth / slideHeight;

      if (slideRatio === rectRatio) {
        hor = 0;
        ver = 0;
      }
      if (slideRatio > rectRatio) {
        sh = 1 / slideRatio;
        rh = 1 / rectRatio;
        diff = (rh / sh) - 1;
        ver = diff / 2 * 100;
        hor = 0;
      }
      if (slideRatio < rectRatio) {
        sw = slideRatio;
        rw = rectRatio;
        diff = (rw / sw) - 1;
        ver = 0;
        hor = diff / 2 * 100;
      }

      return {
        ver,
        hor
      };
    },
    scaledBg() {
      // Внимание! Логика дублируется в imageCssProps@slide_background
      const bg = this.bg;
      const res = _.cloneDeep(this.bg);

      if (bg.imageStyles) {
        const bgImageFitStyles = this.fitBackgroundImage({
          fillMode: bg.styles['image-fill-mode'],
          containerWidth: this.slideWidth,
          imgWidth: bg.styles['background-image-width'],
          imageScale: bg.styles['image-scale'],
          imageShiftX: bg.styles['image-shift-x'],
          imageShiftY: bg.styles['image-shift-y'],
          diff: this.diff
        });

        Object.assign(res.imageStyles, bgImageFitStyles);
      }

      return res;
    },
    bgStyles() {
      let p = this.bg.params;
      if (p == null) {
        p = {
          tx: 0,
          ty: 0,
          scale: 1,
          opacity: 1
        };
      }

      return {
        transform: `translate(${p.tx * 100}%, ${p.ty * 100}%) scale(${p.scale})`,
        opacity: p.opacity
      };
    },
    dataForBgMixin() {
      return {
        styles: this.bg.styles,
        containerWidth: this.slideWidth,
        containerHeight: this.slideHeight
      };
    }
  }
};
</script>

<style>

</style>
