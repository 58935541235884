<template>
    <div>
        <v-client-table
            ref="table"
            :data="data"
            :columns="columns"
            :options="options"
            @filter="filterData"
        >
            <span slot="user" slot-scope="props">
                {{ `#${props.row.user_id} | ${props.row.user_name}` }} <br> {{ props.row.user_email }}
            </span>

            <span slot="activities" slot-scope="props">
                <svg
                    class="icon"
                    viewBox="0 0 640 512"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="() => findUser(props.row.user_id)"
                >
                    <path
                        d="M208 96c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zM123.7 200.5c1-.4 1.9-.8 2.9-1.2l-16.9 63.5c-5.6 21.1-.1 43.6 14.7 59.7l70.7 77.1 22 88.1c4.3 17.1 21.7 27.6 38.8 23.3s27.6-21.7 23.3-38.8l-23-92.1c-1.9-7.8-5.8-14.9-11.2-20.8l-49.5-54 19.3-65.5 9.6 23c4.4 10.6 12.5 19.3 22.8 24.5l26.7 13.3c15.8 7.9 35 1.5 42.9-14.3s1.5-35-14.3-42.9L281 232.7l-15.3-36.8C248.5 154.8 208.3 128 163.7 128c-22.8 0-45.3 4.8-66.1 14l-8 3.5c-32.9 14.6-58.1 42.4-69.4 76.5l-2.6 7.8c-5.6 16.8 3.5 34.9 20.2 40.5s34.9-3.5 40.5-20.2l2.6-7.8c5.7-17.1 18.3-30.9 34.7-38.2l8-3.5zm-30 135.1L68.7 398 9.4 457.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L116.3 441c4.6-4.6 8.2-10.1 10.6-16.1l14.5-36.2-40.7-44.4c-2.5-2.7-4.8-5.6-7-8.6zM550.6 153.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L530.7 224H384c-17.7 0-32 14.3-32 32s14.3 32 32 32H530.7l-25.4 25.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l80-80c12.5-12.5 12.5-32.8 0-45.3l-80-80zM392 0c-13.3 0-24 10.7-24 24V72c0 13.3 10.7 24 24 24s24-10.7 24-24V24c0-13.3-10.7-24-24-24zm24 152c0-13.3-10.7-24-24-24s-24 10.7-24 24v16c0 13.3 10.7 24 24 24s24-10.7 24-24V152zM392 320c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24s24-10.7 24-24V344c0-13.3-10.7-24-24-24zm24 120c0-13.3-10.7-24-24-24s-24 10.7-24 24v48c0 13.3 10.7 24 24 24s24-10.7 24-24V440z"/>
                </svg>
            </span>
        </v-client-table>
        <Pagination
            v-model="page"
            :records="count"
            :per-page="perPage"
            :options="paginationOptions"
            @paginate="page => getData(page)"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import $ from 'jquery';
import { EventBus } from '@/main';
import { apiGet } from '@/models/Api';
import Pagination from 'vue-pagination-2';
import { ClientTable } from 'vue-tables-2';

window.$ = $;
Vue.use(ClientTable, {}, false, 'bootstrap3', {});

export default {
    name: 'UserTable',
    components: {
        Pagination
    },
    data: () => ({
        page: 1,
        count: 0,
        perPage: 20,
        filters: {},
        data: [],
        columns: ['id', 'name', 'user', 'created_at', 'updated_at', 'public', 'activities'],
        options: {
            filterable: ['id', 'name', 'user', 'created_at', 'updated_at', 'public'],
            filterByColumn: true,
            perPage: 20,
            sortable: [],
            perPageValues: [],
            pagination: false,
            listColumns: {
                public: [
                    {
                        id: 0,
                        text: 'Закрытая'
                    },
                    {
                        id: 1,
                        text: 'По ссылке'
                    },
                    {
                        id: 2,
                        text: 'Публичная'
                    },
                    {
                        id: 3,
                        text: 'Открытая'
                    }
                ]
            },
            headings: {
                'id': 'ID',
                'name': 'Название',
                'user': 'Пользователь',
                'created_at': 'Создана',
                'updated_at': 'Отредактирована',
                'public': 'Статус',
                'activities': ''
            },
            columnsClasses: {
                id: 'id_col',
                name: 'name_col',
                user: 'user_col',
                'created_at': 'created_at_col',
                'updated_at': 'updated_at_col',
                'public': 'public_col',
                'activities': 'activities_col'
            },
            filterAlgorithm: {
                id: () => true,
                name: () => true,
                user: () => true,
                'created_at': () => true,
                'updated_at': () => true,
                public: () => true
            },
            texts: {
                count: 'Показано с {from} по {to} из {count} записей|Записей: {count}|Одна запись',
                first: 'Первая',
                last: 'Последняя',
                filter: 'Отфильтровать:',
                filterPlaceholder: 'Поиск:',
                limit: 'Записей:',
                page: 'Страница:',
                noResults: 'Записей не найдено',
                filterBy: '{column}',
                loading: 'Загрузка...',
                defaultOption: '{column}',
                columns: 'Колонки'

            }
        },
        paginationOptions: {
            texts: {
                count: 'Показано с {from} по {to} из {count} записей|Записей: {count}|Одна запись',
                limit: 'Записей:',
                page: 'Страница:',
                noResults: 'Записей не найдено'
            }
        }
    }),
    methods: {
        findUser (id) {
            EventBus.$emit('find-user', id);
        },
        filterData (payload) {
            if (payload.value) {
                this.filters[payload.name] = payload.value;
            } else {
                delete this.filters[payload.name];
            }

            this.getData();
        },
        async getData (page = 1) {
            this.$refs?.table?.setLoadingState(true);

            const res = await apiGet(
                '/api/v1/manager/presentations',
                {
                    ...this.filters,
                    take: this.perPage,
                    skip: this.perPage * (this.page - 1)
                });
            this.$refs?.table?.setLoadingState(false);

            if (res) {
                this.data = res.data;
                this.page = page;
                this.count = res.count;
            }
        },
        refresh () {
            this.filters = {};
            this.$refs.table.resetQuery();
            this.getData(1);
        }
    },
    created () {
        this.getData();
    },
    filters: {}
};
</script>

<style lang="scss">
@import "@/assets/scss/table.scss";

.name_col, .user_col {
    width: 25%;
    max-width: 25%;
}

.created_at_col, .updated_at_col {
    width: 15%;
    max-width: 15%;
}

.user_col, .created_at_col, .updated_at_col {
    user-select: text;
}

.public_col {
    width: 150px;
    max-width: 150px;
}

.activities_col {
    width: 50px;
    max-width: 50px;
    text-align: center;

    .icon {
        width: 25px;
        fill: #82568E;
        cursor: pointer;
    }
}
</style>
