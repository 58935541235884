<template>
  <div class="select">
    <div class="select__input" v-on:click="selectOpen = true">{{ selected.caption }}
      <svg width="10" height="8" xmlns="http://www.w3.org/2000/svg">
        <path transform="rotate(180 23.5 7.024)" d="M42 8l3 4.049h-6z" fill-rule="evenodd"></path>
      </svg>
    </div>
    <div class="select__body" v-show="selectOpen">
      <div class="select__body-scrollable">
        <div class="select__option" v-for="(option) in options" v-on:click="selectOption(option)">
          {{ option.caption }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "CustomSelect",
  inject: ['currentPage'],
  props:['options', 'prop_name', 'default'],

  data() {
    return{
      selected: {},
      selectOpen: false
    };
  },

  methods: {
    selectOption(val){
      this.selected = val;
      this.$emit('selected', {
        property: this.prop_name,
        value: val
      });
    }
  },

  created() {
    if (this.default != null) {
      this.selectOption(this.options[this.default]);
    }
  },

  watch: {
    'currentPage.mousePressed.left'() {
      if (!this.currentPage.mousePressed.left) {
        this.selectOpen = false;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/common.scss';

.select{
  margin: 10px 0;
  color: $space-gray;
  &__input{
    &:hover{
      svg{
        opacity: 1;
      }
    }

    svg{
      position: absolute;
      display: block;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
    }
  }
  &__body{

  }
}
</style>
