<template>
    <div
        class="draggable-header-view"
        @mousemove="onDrag" @touchmove="onDrag"
        @mouseup="stopDrag" @touchend="stopDrag" @mouseleave="stopDrag"
    >
        <svg class="bg" width="100vw" height="560">
            <path :d="headerPath" fill="#333638"></path>
        </svg>
        <div class="header" @mousedown="startDrag" @touchstart="startDrag">
            <div class="container d-flex justify-content-between">
                <router-link class="logo" to="/">
                    <img src="@/assets/images/logo.png" alt="Logo" width="130px" height="28px"/>
                </router-link>
                <div class="links">
                    <span @mousedown.stop.prevent="$emit('page', 'users')" :class="{active: component.users}">
                        Пользователи</span>
                    <span
                        :class="{active: component.presentations}"
                        @mousedown.stop.prevent="$emit('page', 'presentations')"
                    >
                        Презентации</span>
                    <router-link to="/account">
                        Аккаунт
                    </router-link>
                </div>
            </div>
        </div>
        <div class="content container" :style="contentPosition">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
import dynamics from 'dynamics.js';

export default {
    name: 'DraggableHeader',
    props: {
        component: Object
    },
    data () {
        return {
            dragging: false,
            continueDragging: false,
            // quadratic bezier control point
            c: { x: 70, y: 70 },
            // record drag start point
            start: { x: 0, y: 0 }
        };
    },
    computed: {
        headerPath () {
            return `M 0, 0 L 1536, 0 1536, 70 Q ${this.c.x}, ${this.c.y} 0, 70`;
        },
        contentPosition () {
            var dy = this.c.y - 70;
            var dampen = dy > 0 ? 2 : 4;
            return {
                transform: 'translateY(' + dy / dampen + 'px)'
            };
        }
    },
    methods: {
        startDrag (e) {
            e = e.changedTouches ? e.changedTouches[0] : e;
            this.dragging = true;
            this.start.x = e.pageX;
            this.start.y = e.pageY;
        },
        onDrag (e) {
            e = e.changedTouches ? e.changedTouches[0] : e;
            if (this.dragging) {
                this.continueDragging = true;
                this.c.x = e.pageX;
                // dampen vertical drag by a factor
                const dy = e.pageY - this.start.y;
                const dampen = dy > 0 ? 1.5 : 4;
                this.c.y = 70 + dy / dampen;
            }
        },
        stopDrag () {
            if (this.dragging && this.continueDragging) {
                this.dragging = false;
                this.continueDragging = false;
                dynamics.animate(
                    this.c,
                    {
                        x: 70,
                        y: 70
                    },
                    {
                        type: dynamics.spring,
                        duration: 700,
                        friction: 280
                    }
                );

                this.$emit('drag-done');
            }

            this.dragging = false;
        }
    }
};
</script>

<style scoped lang="scss">
.draggable-header-view {
    background-color: #fff;
    //box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    width: 100vw;
    min-height: 100vh;
    //overflow: hidden;
    position: relative;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .header, .content {
        z-index: 1;
        position: relative;
        box-sizing: border-box;
    }

    .header {
        height: 70px;
        background-color: #333638;
    }

    .content {
        color: #333;
        line-height: 1.5em;
    }
}

.header {
    padding: 0;

    .links {
        a, span {
            color: #F1F1F1;
            cursor: pointer;
            font-size: 12px;
            font-weight: bold;
            line-height: 22px;
            letter-spacing: 1px;
            padding-bottom: 3px;
            text-decoration: none;
            text-transform: uppercase;

            &:not(:last-child) {
                margin-right: 14px;
            }

            &.active {
                border-bottom: 2px solid #82568E;
            }
        }
    }
}

.content {
    width: 100vw;
}
</style>
