/* eslint-disable */
import _ from 'lodash';

export default {
  data(){
    return {
      fonts: [
        'Roboto',
        'Roboto Condensed',
        'Raleway',
        'Open Sans',
        'Noto Sans',
        'Ubuntu',
        'Montserrat',
        'Lato',
        'Lora',
        'PT Sans',
        'PT Sans Narrow',
        'PT Serif',
        'Oswald',
        'Merriweather',
        'Roboto Mono',
        'Inconsolata',
        'Source Code Pro',
        'Permanent Marker',
        'Lobster'
      ],
      fontsDataCss: {},
      href: ''
    }
  },

  methods: {
    fontsInit(){
      const fontString = _.clone(
        this.fonts
      ).sort(
      ).map(
        f=>f.split(
          ' '
        ).join(
          '+'
        )
      ).join(
        '|'
      )

      this.href = 'https://fonts.googleapis.com/css?family=' + fontString
      this.GFontsToDataURI(this.fonts)
    },

    GFontsToDataURI(fontsList) {
      const fontString = this.generateFontsString(fontsList)
      this.href = 'https://fonts.googleapis.com/css?family=' + fontString

      return fetch(this.href) // first fecth the embed stylesheet page
        .then(resp => resp.text()) // we only need the text of it
        .then(text => {
          // now we need to parse the CSSruleSets contained
          // but chrome doesn't support styleSheets in DOMParsed docs...
          const s = document.createElement('style');
          s.innerHTML = text;
          document.head.appendChild(s);
          const styleSheet = s.sheet

          // this will help us to keep track of the rules and the original urls

          const fontRules = [];
          const fontProms = [];

          // iterate through all the cssRules of the embedded doc
          // Edge doesn't make CSSRuleList enumerable...
          for (let i = 0; i < styleSheet.cssRules.length; i++) {
            const r = styleSheet.cssRules[i];
            const fR = this.fontRule(r);
            if (!fR) {
              continue;
            }
            fontRules.push(fR);
            fontProms.push(
              fetch(fR.url) // fetch the actual font-file (.woff)
                .then(resp => resp.blob())
                .then(blob => {
                  return new Promise(resolve => {
                    // we have to return it as a dataURI
                    // because for whatever reason,
                    // browser are afraid of blobURI in <img> too...
                    const f = new FileReader();
                    f.onload = e => resolve(f.result);
                    f.readAsDataURL(blob);
                  })
                })
                .then(dataURL => {
                  // now that we have our dataURI version,
                  //  we can replace the original URI with it
                  //  and we return the full rule's cssText
                  fR.dataUriCssText = fR.rule.cssText.replace(fR.url, dataURL)

                  if (this.fontsDataCss[fR.name] === undefined){
                    this.fontsDataCss[fR.name] = []
                  }
                  this.fontsDataCss[fR.name].push(fR.dataUriCssText)

                  return fR
                })
            )
          }
          return Promise.all(fontProms); // wait for all this has been done
        });
    },

    generateFontsString(fontsArray){
      const fontString = _.clone(fontsArray).sort().map(
        f=>f.split(
          ' '
        ).join(
          '+'
        )
      ).join(
        '|'
      )
      return fontString
    },

    fontRule(rule){
      const src = rule.style.getPropertyValue('src') || rule.style.cssText.match(/url\(.*?\)/g)[0];

      let name = rule.cssText.match(/font-family: [\s\S]+?;/)[0]
      name = name.replace('font-family: ', '')
      name = name.replace(';', '')
      name = name.replace(/"/g, '')

      if (!src) return null;
      const url = src.split('url(')[1].split(')')[0];
      return {
        rule,
        src,
        url: url.replace(/"/g, ''),
        dataUriCssText: '',
        name
      }
    }
  },

  mounted(){
    this.fontsInit()
  }
}
