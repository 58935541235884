export default {
    'desktop': {
        'chrome': '>66',
        'chromium': '>66',
        'edge': '>=79'
    },
    'Chrome OS': {
        'chrome': '>66',
        'chromium': '>66'
    }
};
