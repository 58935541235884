<template>
    <div class="thumbs-bar__item-inner">
        <template v-if="vjson || previewHtml">
            <AnimationSlide :bg="vjson.bg" :diff="1" :slideWidth="size.w" :slideHeight="size.h"/>
            <div class="slide" v-if="previewHtml" v-html="previewHtml.slide" :style="slideStyle"></div>
            <div class="slide" :style="slideStyle" v-if="!previewHtml && vjson">
                <AnimationObject
                    v-for="object in vjson.objects"
                    :obj="object"
                    :key="object.id"
                    :slideSize="size"
                    :bgAndContentDifference="{x: 1, y: 1}"
                />
            </div>
        </template>
    </div>
</template>

<script>
import FontsMixin from '@/mixins/fonts';
import AnimationSlide from "@/components/AnimationSlide";
import AnimationObject from "@/components/AnimationObject";

export default {
    name: "SlidePreviewContent",
    props: {
        vjson: Object,
        previewHtml: [String, Object],
        size: Object,
        meta: Object
    },
    components: {
        AnimationSlide,
        AnimationObject
    },
    mixins: [FontsMixin],
    data () {
        return {
            transform: ''
        };
    },
    computed: {
        slideStyle () {
            return {
                width: `${this.size.w}px`,
                height: `${this.size.h}px`,
                transform: this.transform,
                'backface-visibility': 'hidden'
            };
        }
    },
    methods: {
        countTransformation () {
            const vh = this.size.h;
            const vw = this.size.w;
            const ph = this.$el.offsetHeight;
            const pw = this.$el.offsetWidth;

            const scaleX = pw / vw;
            const scaleY = ph / vh;

            const translationX = scaleX * 50;
            const translationY = scaleY * 50;


            this.transform = `translate(-${translationX}%, -${translationY}%) scale3d(${scaleX}, ${scaleY}, 1)`;
        }
    },
    mounted () {
        this.countTransformation();

        addEventListener("resize", () => this.countTransformation());
    }
};
</script>

<style lang="scss">
.thumbs-bar__item-inner {
    height: 100%;
}
</style>
