/* eslint-disable */

export default {
  name: 'Bg_mixin',
  methods: {
    fitBackgroundImage(opts) {
      let containerHeight, containerWidth, diff, fillMode, imageHeight, imageScale, imageShiftX, imageShiftY, imageWidth, imgWidth, k, maxShift, styles;
      ({fillMode, containerWidth, imgWidth, imageScale, imageShiftX, imageShiftY, diff} = opts);
      styles = {};
      switch (fillMode) {
        case 'stretch':
          styles['background-size'] = '100% 100%';
          break;
        case 'cover':
          styles['background-size'] = 'cover';
          break;
        case 'tile':
          styles['background-repeat'] = 'repeat';
          if (diff == null) {
            diff = 1;
          }
          if ((containerWidth != null) && (imgWidth != null)) {
            k = (imgWidth / containerWidth) / diff;
          } else {
            k = 1;
          }
          styles['background-size'] = `${imageScale * k * 100}%`;
          break;
        case 'custom':
          styles['background-size'] = "100%";
          styles['background-position'] = "center";
          styles.transform = "translate(-50%, -50%)";
          ({imageWidth, imageHeight, containerWidth, containerHeight} = this.imageAndContainerRects);
          maxShift = this.maxImageShift;
          styles.height = `${(imageHeight / containerHeight) * 100}%`;
          styles.width = `${(imageWidth / containerWidth) * 100}%`;
          styles.left = `${50 + maxShift.x * imageShiftX}%`;
          styles.top = `${50 + maxShift.y * imageShiftY}%`;
      }
      return styles;
    }
  },
  computed: {
    imageAndContainerRects() {
      let containerHeight, containerRatio, containerWidth, imageHeight, imageWidth, imgRatio, sc, styles;
      ({styles, containerWidth, containerHeight} = this.dataForBgMixin);
      if (styles['image-fill-mode'] === 'custom') {
        imageWidth = styles['background-image-width'];
        imageHeight = styles['background-image-height'];
        imgRatio = imageWidth / imageHeight;
        if (this.slideEars && !this.boxed) {
          containerHeight = (1 + this.slideEars.ver * 2 / 100) * containerHeight;
          containerWidth = (1 + this.slideEars.hor * 2 / 100) * containerWidth;
        }
        containerRatio = containerWidth / containerHeight;
        if (imgRatio > containerRatio) { // фон шире, выступает вправо-влево
          imageHeight = containerHeight;
          imageWidth = imageHeight * imgRatio; // фон уже, выступает вверх-вниз
        } else {
          imageWidth = containerWidth;
          imageHeight = imageWidth / imgRatio;
        }
        sc = styles['image-custom-scale'];
        imageWidth *= sc;
        imageHeight *= sc;
        return {imageWidth, imageHeight, containerWidth, containerHeight};
      } else {
        return false;
      }
    },
    maxImageShift() {
      let containerHeight, containerWidth, imageHeight, imageWidth;
      if (this.imageAndContainerRects) {
        ({imageWidth, imageHeight, containerWidth, containerHeight} = this.imageAndContainerRects);
        return {
          x: (((imageWidth / containerWidth) - 1) * 100) / 2,
          y: (((imageHeight / containerHeight) - 1) * 100) / 2
        };
      } else {
        return false;
      }
    },
    imageOverflow() {
      let containerHeight, containerWidth, imageHeight, imageWidth;
      if (this.imageAndContainerRects) {
        ({imageWidth, imageHeight, containerWidth, containerHeight} = this.imageAndContainerRects);
        return {
          x: (imageWidth - containerWidth) / 2,
          y: (imageHeight - containerHeight) / 2
        };
      } else {
        return false;
      }
    },
    percentPerPixel() {
      let x, y;
      if (this.imageAndContainerRects) {
        x = 100 / this.imageOverflow.x;
        y = 100 / this.imageOverflow.y;
        if (x === 2e308) {
          x = 0;
        }
        if (y === 2e308) {
          y = 0;
        }
        return {x, y};
      } else {
        return false;
      }
    }
  }
};
